.navigation {
  padding: 10px 0;
  .menu_dropdown {
    background: transparent;
    padding: 0;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    span {
      font-size: 20px;
      display: flex;
    }
  }
  .menu {
    background: transparent;
    padding: 0;
    padding-left: 76px;
    .collapse {
      display: flex !important;
      > .navbar-nav {
        width: 100%;
        // justify-content: space-between;
      }
    }
    .drop-btn {
      background: none;
      border: none;
      color: black;
      &:hover {
        background: none;
        border: none;
        color: black;
      }
      &:focus {
        background: none;
        border: none;
        color: black;
      }
    }
    .drop_ic {
      background: none;
      border: none;
      color: black;
      &:hover {
        background: none;
        border: none;
        color: black;
      }
    }
  }
  .bg-light {
    background: transparent !important;
    padding: 0;
  }
  .navbar {
    a {
      color: #000 !important;
      text-transform: uppercase;
      &:hover {
        opacity: 0.6;
      }
    }
  }
  .dropdown-menu {
    a {
      font-size: 15px;
      text-transform: capitalize;
      display: block;
      padding: 0.25rem 1.5rem;
      text-decoration: none;
    }
  }
  //////////////////////// New Navigation /////////////////////////
  .all_category {
    display: flex;
    align-items: center;

    @media (max-width: 991px) {
      display: flex;
      align-items: center;
      width: 100% !important;
      max-width: 100% !important;
      flex: 100%;
      background: #0a368d;
      padding: 10px;
    }
    .all_category_title {
      margin-left: 10px;
      @media (max-width: 991px) {
        color: #fff;
      }
    }
    .child_trigger {
      margin-left: 5px;
    }
    a {
      color: #000;
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
      &.active {
        color: #0056b3;
        font-weight: bold;
        background-color: #aaa;
      }
    }
    .category {
      margin: 0;
      padding: 10px 0;
      position: absolute;
      width: 100%;
      background: #fff;
      top: 100%;
      display: none;
      background: #fff;
      box-shadow: 0px 0px 3px #aaa;
      z-index: 9999;
      min-width: 220px;

      @media (max-width: 991px) {
        display: block;
        width: 100%;
        max-width: 100%;
        left: 0;
        padding: 10px;
        overflow: hidden;
      }
      > ul {
        margin: 0;
        li {
          position: relative;

          @media (max-width: 991px) {
            flex-direction: column;
          }
          .back_button {
            position: absolute;
            top: 0px;
            right: 0;
            padding: 5px 20px;
            cursor: pointer;
            color: inherit;

            svg {
              width: 20px;
              height: 17px;
              transform: rotate(-90deg);
              transition: transform 0.3s ease-in-out;
            }
          }
          a {
            text-decoration: none;
            padding: 5px 10px;
            display: block;
            text-transform: uppercase;

            &:hover {
              text-decoration: none;
            }
          }

          .sub_category {
            position: absolute;
            left: 100%;
            width: 100%;
            top: 0;
            padding: 10px;
            width: 500px;
            background: #fff;
            box-shadow: 0px 0px 3px #aaa;
            z-index: 999;
            box-shadow: 0px 0px 3px #aaa;
            transform: scale(0) rotate(0deg);
            -ms-transform: scale(0) rotate(0deg);
            -moz-transform: scale(0) rotate(0deg);
            -webkit-transform: scale(0) rotate(0deg);
            -o-transform: scale(0) rotate(0deg);
            visibility: hidden;
            opacity: 0;
            z-index: 999;
            -moz-transition: all 0.2s ease-in-out 0s;
            -webkit-transition: all 0.2s ease-in-out 0s;
            -o-transition: all 0.2s ease-in-out 0s;
            -ms-transition: all 0.2s ease-in-out 0s;
            transition: all 0.2s ease-in-out 0s;
            transform: translateY(40px);

            @media (max-width: 991px) {
              width: 100%;
              max-width: 100%;
              display: block;
              box-shadow: none;
              padding: 0;
              overflow-y: auto;
              padding: 10px 0;
              transition: opacity 2s linear;
              overflow-y: auto;
              visibility: hidden;
              opacity: 0;

              li {
                a {
                  border-bottom: 1px solid #aaa;
                }
              }
            }
            h5 {
              padding-left: 10px;
              border-bottom: 1px solid #005195;
              margin-bottom: 5px;
              padding-bottom: 10px;

              @media (max-width: 991px) {
                display: none;
              }
            }
          }

          &:hover {
            @media (min-width: 992px) {
              .sub_category {
                -moz-transition: all 0.2s ease-in-out 0s;
                -webkit-transition: all 0.2s ease-in-out 0s;
                -o-transition: all 0.2s ease-in-out 0s;
                -ms-transition: all 0.2s ease-in-out 0s;
                transition: all 0.2s ease-in-out 0s;
                transform: scale(1) rotate(0deg);
                -ms-transform: scale(1) rotate(0deg);
                -moz-transform: scale(1) rotate(0deg);
                -webkit-transform: scale(1) rotate(0deg);
                -o-transform: scale(1) rotate(0deg);
                visibility: visible;
                opacity: 1;
                transform: translateY(0);
              }
            }
          }
          &.show {
            .back_button {
              svg {
                transform: rotate(0deg);
              }
            }
            @media (max-width: 991px) {
              .sub_category {
                transition: opacity 2s linear;
                visibility: visible;
                opacity: 1;
                position: static;
                margin-bottom: 15px;
                transform: translateY(0);
              }
            }
          }
        }
      }
      li {
        &:hover {
          background-color: #f1f1f1;
          @media (max-width: 767px) {
            background-color: transparent;
          }
          > a {
            color: #005195;
          }
          .back_button {
            color: #005195;
            @media (min-width: 991px) {
              svg {
                transform: rotate(0deg);
              }
            }
          }
        }
      }
    }

    &:hover {
      .category {
        display: block;
      }
    }
  }

  // ....................................................

  .navigation_menu {
    z-index: 999;
    @media (max-width: 991px) {
      display: none;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      li {
        position: relative;

        a {
          padding: 7px 17px 7px 10px;
          color: #000;
          list-style: none;
          border-radius: 5px;
          &:hover {
            text-decoration: none;
            color: #0a368d;
          }
          &.active {
            color: #0a368d;
            font-weight: bold;
            background-color: #aaa;
          }
          .back_button {
            display: inline-block;
            position: absolute;
          }
        }

        ul {
          display: block;
          position: absolute;
          top: 100%;
          width: 250px;
          padding-top: 10px;
          padding-bottom: 10px;
          text-align: left;
          justify-content: flex-start;
          background: #fff;
          box-shadow: 0px 0px 3px #aaa;
          transform: scale(0) rotate(0deg);
          -ms-transform: scale(0) rotate(0deg);
          -moz-transform: scale(0) rotate(0deg);
          -webkit-transform: scale(0) rotate(0deg);
          -o-transform: scale(0) rotate(0deg);
          visibility: hidden;
          opacity: 0;
          z-index: 999;
          -moz-transition: all 0.2s ease-in-out 0s;
          -webkit-transition: all 0.2s ease-in-out 0s;
          -o-transition: all 0.2s ease-in-out 0s;
          -ms-transition: all 0.2s ease-in-out 0s;
          transition: all 0.2s ease-in-out 0s;
          transform: translateY(40px);
          z-index: 1000;

          li {
            padding: 5px;
          }
        }

        &:hover {
          ul {
            -moz-transition: all 0.2s ease-in-out 0s;
            -webkit-transition: all 0.2s ease-in-out 0s;
            -o-transition: all 0.2s ease-in-out 0s;
            -ms-transition: all 0.2s ease-in-out 0s;
            transition: all 0.2s ease-in-out 0s;
            transform: scale(1) rotate(0deg);
            -ms-transform: scale(1) rotate(0deg);
            -moz-transform: scale(1) rotate(0deg);
            -webkit-transform: scale(1) rotate(0deg);
            -o-transform: scale(1) rotate(0deg);
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
          }
        }
        &:last-child {
          ul {
            right: 0;
            left: auto;
          }
        }
      }
    }
  }
  ////////////////// ENd /////////////////////////////////
}

@media (max-width: 1199px) {
  .navigation {
    .navbar {
      a {
        padding-right: 0 !important;
        padding-left: 10px !important;
      }
    }
    .menu {
      padding-left: 0;
    }
  }
}

@media (max-width: 991px) {
  .navigation {
    display: block;
    width: 100%;
    height: calc(100vh - 54px);
    position: fixed;
    z-index: 1000;
    background: #f1f1f1;
    overflow-y: auto;
    color: #fff;
    left: -100%;
    transition: all 0.7s ease;
    max-width: 320px;
    top: 0;
    padding-top: 0;
    .menu_dropdown {
      .navbar {
        .collapse {
          display: block;
          .dropdown-menu {
            background-color: transparent !important;
            a {
              font-size: 14px !important;
            }
          }
          .menu_close.close {
            position: absolute;
            top: 0;
            right: 0;
            padding: 15px;
            color: #fff;
            opacity: 1;
            font-weight: 400;
            font-size: 17px;
            cursor: pointer;
            z-index: 99;
          }
        }
      }
    }
    .menu {
      display: none;
    }
  }

  .header.active_cat {
    .mobile_button {
      button {
        &.menu_open {
          display: none;
        }
        &.menu_close {
          display: block;
        }
      }
    }
    .navigation {
      transition: all 0.7s ease;
      left: 0;
    }
  }

  ::-webkit-scrollbar {
    width: 5px;
  }
}

@media (min-width: 992px) {
  .navigation .all_category .category > ul > li {
    overflow: hidden;
  }

  .navigation .navigation_menu ul li ul {
    width: 100%;
    left: 0;
    min-height: 80px;
    box-shadow: -1px 1px 3px #aaa;
  }

  .navigation .navigation_menu ul li {
    position: unset;
  }

  .navigation .menu {
    position: unset;
  }

  .navigation .container .row {
    position: relative;
  }

  .navigation .navigation_menu ul li ul {
    top: 33px;
    max-height: 411px;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .navigation .navigation_menu ul li ul > li {
    width: 33.33%;
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    border-left: 1px solid #005195;
  }

  .navigation .navigation_menu ul li ul > li > a {
    display: inline-block;
    padding: 0;
    min-height: auto;
    line-height: normal;
  }

  .navigation .navigation_menu ul li ul > li > a:after {
    border-bottom: 2px solid #0a368db0;
    border-radius: 0;
    width: 0;
    content: "";
    top: 1px;
    left: 0;
    transition: all 0.25s ease-in-out;
    display: block;
    position: relative;
  }

  .navigation .navigation_menu ul li ul > li > a:hover:after {
    width: 100%;
  }

  .navigation .all_category .category > ul > li:hover {
    overflow: unset;
  }

  .navigation .all_category .category > ul li {
    position: relative;
  }

  .navigation .all_category .category > ul li ul > li {
    width: 50%;
    display: inline-block;
  }

  .navigation .all_category .category > ul li ul > li > a:after {
    border-bottom: 2px solid #0a368db0;
    border-radius: 0;
    width: 0;
    content: "";
    top: 1px;
    left: 0;
    transition: all 0.25s ease-in-out;
    display: block;
    position: relative;
  }

  .navigation .all_category .category > ul li ul > li > a:hover:after {
    width: 100%;
  }

  .navigation .all_category .category li:hover {
    background-color: #fff;
  }

  .navigation .all_category .category > ul li a {
    display: inline-block;
  }

  .navigation .all_category .category > ul li .sub_category {
    width: 650px;
  }

  .navigation .all_category .category > ul li ul > li:nth-child(even) {
    border-left: 1px solid #005195;
  }

  .navigation .navigation_menu ul li ul > li:nth-child(3n + 1) {
    border-left: 0;
  }

  .navigation .navigation_menu ul li ul {
    border-top: 2.5px solid #f73400;
  }
}


@media(max-width:992px){
  .navigation .all_category .category > ul li.section.open .sub_category {
    position: relative;
    opacity: 1;
    visibility: visible;
    left: 0;
    top: 0;
    margin-left: 10px;
    transform: translate(0);
    padding: 10px 0;
}


.navigation .all_category .category > ul li.section.open .sub_category ul>li {
    font-size: 12px;
}

.navigation .all_category .category > ul li.section.open .sub_category ul>li>a {
    border-bottom: 0;
}


.navigation .all_category .category > ul li.section .sub_category {
    transition: none;
}
.navigation .all_category .category > ul li.section.open .back_button {
  transform: rotate(-90deg);
}



.navigation .all_category .category > ul li .back_button {
  transform: rotate( 90deg);
  transition: all .2s linear;
  color:#0a368d;

}
.navigation .all_category .category > ul li.section.open>a {
  background-color: #ececec;
}

}