.order_detail {
  .my-heading {
    display: flex;
    justify-content: space-between;
    @media (max-width: 575px) {
      // flex-direction: column;
    }
    h4 {
      margin: 0;
      padding: 0;
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
      color: #212529;
    }
    .order_button {
      button {
        outline: none;
        border: none;
        margin: 0 5px;
        padding: 4px 10px;
        border-radius: 5px;
        font-size: 12px;
        box-shadow: 0px 0px 3px #aaa;
        background: #fff;
        font-weight: 500;
        &:first-child {
          margin-left: 0;
        }
        &:hover {
          color: #f44336;
        }
        &.track_order_button {
          display: none;
        }
        @media (max-width: 575px) {
          // margin-top: 15px;
        }
      }
    }
  }

  h3 {
    margin-bottom: 20px;
    @media (max-width: 575px) {
      font-size: 20xp;
    }
  }
  .user_account {
    margin-top: 20px;

    .order_id {
      ul {
        width: 100%;
        max-width: 530px;
        li {
          display: flex;
          justify-content: space-between;
          padding-bottom: 10px;
          text-transform:capitalize
        }
      }
    }
    .notes {
    }
    .order_description {
      ul.order_discription {
        font-weight: 700;
        padding-top: 15px;
        li {
          display: flex;
          justify-content: space-between;
          padding: 7px 5px;
          border-bottom: 1px solid #aaa;
          margin-bottom: 5px;
          font-size: 12px;
          &:first-child {
            border-top: 1px solid #aaa;
          }
          a {
            color: inherit;
            max-width: 258px;
            width: 100%;
            display: block;
          }
          .Price {
            max-width: 100px;
            width: 100%;
            @media (max-width: 575px) {
              padding: 0 5px;
            }
          }
          .item {
            max-width: 258px;
            width: 100%;

            // @media (max-width: 575px) {
            //   max-width: 40%;
            // }
          }
        }
      }
      .order_price {
        text-align: right;
        padding: 10px 20px;
        li {
          strong {
            padding-right: 20px;
          }
        }
      }
    }
    .order_address {
      .billing_address {
        li {
          padding: 5px;
          line-height: 1.5;
          @media (max-width: 575px) {
            // margin-bottom: 10px;
          }
          strong {
            display: inline-block;
            max-width: 140px;
            width: 100%;

            @media (max-width: 991px) {
              max-width: none;
            }
          }
        }
      }
    }
  }
}
.order_detail .user_account .order_description ul.order_discription li a p span {
  font-size: 11px;
  font-weight: 700;
}

p.gst-price span {
  font-size: 10px;
  font-weight: 500;
  font-style: italic;
}

p.gst-price {
  margin-bottom: 5px;
  font-weight: 700;
}

.order_detail .user_account .order_description ul.order_discription li .Price.qty {
  max-width: 50px;
  text-align: center;
}

.cancel-order-inner .modal-header {
  padding: 10px 16px;
  background-color: #ff0000bd;
  color: #fff;
}

.modal-header {
}

.cancel-order-inner .modal-header .close {
  color: #fff;
  opacity: 1;
  margin: 0;
  padding: 5px;
}

.cancel-order-inner .modal-header .close:hover {
  opacity: 1;
}

.cancel-order-inner .modal-body {
  font-size: 15px;
  font-weight: 500;
}

.cancel-order-inner .modal-body .cancel-reason-wrap {
  margin-top: 20px;
}

.cancel-order-inner .modal-body .cancel-reason-wrap textarea {
  cursor: pointer;
  border: 1px solid grey;
  transition: all .15s linear;
  margin-bottom: 0;
  display: block;
  width: 100%;
  padding: 5px;
}

textarea {
}

.cancel-order-inner .modal-body .cancel-reason-wrap label {
  display: block;
  font-size: 14px;
  margin: 0;
  margin-bottom: 5px;
  font-weight: 500;
  color: #005195;
  color: #212529ba;
}

.cancel-order-inner .modal-body .cancel-reason-wrap textarea:focus {
  outline: none;
  outline: none;
  border: 1px solid #00448d;
  box-shadow: 0 0 2px 0 #00448d5c;
}

.cancel-order-inner .modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 11px;
  border-top: 0;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.cancel-order-inner .modal-footer button {
  background-color: transparent;
  color: #3e3838;
  border: none;
  margin: 0;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 13px;
  transition: all .2s linear;
}

.cancel-order-inner .modal-footer button.btn-danger {
  color: #ff0000bd;
  margin-left: 5px;
}

.cancel-order-inner .modal-footer button:hover {
  background-color: #80808026;
}

.cancel-order-inner .modal-body .cancel-reason-wrap label span {
  margin-left: 5px;
  font-size: 11px;
  font-style: italic;
}
.cancel-watermark {
  border-radius: 20px;
  padding: 4px 20px;
  width: auto;
  margin-left: 0;
  margin-right: 0;
  min-width: unset;
  font-style: italic;
  box-shadow: none;
  color: #343a40;
  background-color: #ff3601c2;
  position: relative;
  top: -1px;
  font-size: 13px;
  color: #fff;
  letter-spacing: 1px;
}
.cancel-watermark {
  border-radius: 20px;
  padding: 4px 20px;
  width: auto;
  margin-left: 0;
  margin-right: 0;
  min-width: unset;
  font-style: italic;
  box-shadow: none;
  color: #343a40;
  background-color: #ff3601c2;
  position: relative;
  top: -1px;
  font-size: 13px;
  color: #fff;
  letter-spacing: 1px;
}

.order_detail .user_account .order_id ul li.cancelled span {
  color: #e70505;
  font-style: italic;
  font-weight: 500;
  text-transform: capitalize;
}

.download-invoce {
  text-transform: none;
  position: relative;
}

.download-invoce a:hover {
  text-decoration: none;
}