.sell_bellstone {
  form {
    .form-group {
      .form-select {
        display: block;
        width: 100%;
        padding: 0.375rem 2.25rem 0.375rem 0.75rem;
        -moz-padding-start: calc(0.75rem - 3px);
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        background-size: 16px 12px;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        -webkit-appearance: none;
        appearance: none;

        &:focus {
          border-color: #86b7fe;
          outline: 0;
          box-shadow: 0 0 0 3px rgba(13, 110, 253, 0.25);
        }
      }
    }
    .mb-3.row {
      @media (max-width: 767px) {
        flex-direction: column;
        margin-bottom: 0 !important;
      }
    }
    textarea.form-control {
      height: 140px !important;
    }
  }
}
