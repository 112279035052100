$logoColor: #16438d;
$navbarColor: #000;
$whiteColor: #fff;

.tab-pane {
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #ececec;
    border-radius: .25rem;
    padding: 25px;
    .contact-heading {
        padding: .75rem 1.25rem;
        margin-bottom: 0;
        background-color: #f3f4f6;
        border-bottom: 1px solid #f3f4f6;
        font-weight: 600;
        font-size: 16px;
    }
    .contact-row {
        margin: 35px 0 25px 0;
        .contact-6 {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            font-size: 14px;
            font-weight: 700;
            align-items: center;
            .profile-image{
                position: relative;
                width: 70%;
                height: 100px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                border: 1px dashed #707070;
                //display: flex;
                align-items: center;
                text-align: center;
                justify-content: center;
                float: right;
                label{
                    cursor: pointer;
                    //margin: 33px 1px 0 1px;
                    &:hover{
                        font-size: 16px;
                    }
                }
                img{
                    width: 84%;
                    height: 92px;
                    object-fit: contain;
                    
                }
        
                input{
                    width: 1px;
                    height: 1px;
                    opacity: 0;
                    position: absolute;
                    overflow: hidden;
                    z-index: -99;
                }
            }
            label {
                margin-bottom: 0;
            }
            @media (min-width: 768px) and (max-width: 991.98px) {
                label {
                  font-size: 12px;
                  padding-right: 5px;
                }
            }
            input {
                max-width: 350px;
                width: 100%;
                border: 1px solid #e2e1e1;
                height: 35px;
                border-radius: 4px;
                padding-left: 10px;
                font-size: 14px;
            }
            @media (min-width: 768px) and (max-width: 991.98px) {
                input {
                  font-size: 12px;
                }
            }
            
        }
        .eqy-btn{
            margin-top: 25px;
            button {
                background: #e30613;
                color: #fff;
                border-radius: 5px;
                padding: 8px 25px;
                border: none;
                font-size: 14px;
            }
            button:hover {
                background: #c90512;
            }
        }
    }
}


.my-heading {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: #f3f4f6;
    border-bottom: 1px solid #f3f4f6;
    font-weight: 600;
    font-size: 22px;
}
.user-account {
    padding: 0px 15px;

    .nav-tabs {
        border-bottom: 0;
        padding: 25px 0px;

        a {
            width: 25%;
            border: 0;
            text-align: center;
            background: $whiteColor;
            color: $navbarColor;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            padding: 10px 0;
            font-size: 16px;
            font-weight: 600;
        }

        a:hover {
            background-color: $logoColor;
            border-radius: 4px;
            color: $whiteColor;

        }

        @media (max-width: 575.98px) {
            a {
                width: 48%;
            }
        }

        a.active {
            background: #16438d;
            font-weight: 600;
            color: #fff;
            border-radius: 0;
            margin: 0px 5px;
        }
    }




}