.payment_methods {
    .common_pages_content {
      .payment_mode {
        padding-left: 20px;
  
        p {
          font-size: 13px;
          b {
            padding-right: 10px;
          }
        }
        h6 {
          font-size: 14px;
        }
      }
    }
  }
  