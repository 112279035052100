* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

/* /////////// List //////////// */
ul {
  list-style: none;
}

a {
  -webkit-transition: 0.3s ease-out;
  -moz-transition: 0.3s ease-out;
  -o-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
}

img {
  display: inline-block;
  vertical-align: middle;
  margin: auto;
  max-width:100%;
}


/* /////////// Heading /////////// */
h3 {
  font-size: 22px;
  text-transform: uppercase;
  padding-top: 20px;
}
h5 {
  font-size: 15px;
  font-weight: 600;
  padding-top: 10px;
}

/* //////////// Form ////////// */
input {
  display: block;
  width: 100%;
  padding: 5px;
  border: none;
  min-height: 40px;
  margin-bottom: 10px;
}

input[type="checkbox"],
input[type="radio"] {
  display: inline-block;
  min-height: auto;
  width: auto;
  padding: 0;
  margin: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

label {
  display: inline-block;
  margin-bottom: 5px;
}

.spacing {
  padding: 20px 0;
}

.button {
  padding: 10px 35px;
  border: 0;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 20px;
  color: #fff;
  transition: background-color 0.3s ease-out;
  background: #00448d;
}

.cart_button {
  background: #f84819;
  border-color: #f84819;
  border-radius: 0;
  padding: 12px 34px;
  text-transform: uppercase;
  border: 2px solid #f84819;
  transition: background-color 0.3s ease-out;
  color: #fff;
}
.cart_button:hover {
  background-color: #f86119;
  border-color: #f86119;
}
.button:hover {
  background-color: #0061d5;
}

@media (max-width: 767px) {
  h3 {
    font-size: 15px;
  }
}

/* ///////////////////////// Bread Crum ///////////////////////// */

.bread_crum {
  background: #ececec;
  margin-bottom: 15px;
}

.bread_crum ul {
  display: flex;
  margin-bottom: 0;
}

.bread_crum ul > li {
  padding: 2px 4px;
}

.common_pages_inner ol li,
.common_pages_inner ul li {
  margin: 10px 0;
}

.common_pages_inner ol > li {
  padding-bottom: 5px;
}

.bread_crum ul > li:not(:first-child) {
  margin-left: 10px;
}

.bread_crum ul > li span {
  padding-left: 10px;
}

.bread_crum ul > li > a {
  color: #000;
}

.bread_crum ul > li > a:hover {
  color: #0061d5;
}
.bread_crum ul > li:last-child span {
  display: none;
}
.bread_crum ul > li > a:hover {
  text-decoration: none;
}

@media (max-width: 575px) {
  .bread_crum {
    display: none;
  }
}

/* /////////////// Common Pages////////////////////////// */

.common_pages {
  padding: 30px;
  box-shadow: 0 0 10px #ccc;
  margin: 40px 0 20px;
}

.common_pages .common_pages_heading {
  padding: 5px 0;
  background: #005195;
}

.common_pages .common_pages_heading h5 {
  padding-left: 10px;
  padding-right: 10px;
  color: #fff;
  font-size: 18px;
}
.common_pages_content {
  padding: 20px 10px;
}

.common_pages_inner p {
  text-align: justify;
}
.common_pages_inner ul {
  list-style-type: upper-roman;
  padding-left: 40px;
  line-height: 1.6;
}

.common_pages_content .date {
  padding-bottom: 15px;
  font-size: 13px;
}

.common_pages_content h6 {
  padding: 10px 0;
  font-size: 15px;
}

@media (max-width: 767px) {
  .common_pages_inner ul {
    padding-left: 20px;
  }
  .common_pages {
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

.disabled {
  cursor: not-allowed !important;
}



body iframe .tawk-icon-right img {
  display: none!important;
}

.tawk-bubble-container .tawk-icon-right img {
  display: none;
}


body iframe{
  display: none;
}