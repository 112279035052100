.cm-modal-wrap .modal-header {
  border-bottom: 1px solid #00519547;
}

.cm-modal-wrap .modal-title {
  font-weight: 600;
  color: #212529;
}

.cm-modal-wrap .form-group {
  margin-bottom: 8px;
  align-items: center;
}

.cm-modal-wrap .col-form-label {
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 12px;
  font-weight: 500;
}

.cm-modal-wrap input {
    min-height: 35px;
    font-size: 14px;
}

.cm-modal-wrap .form-control-sm {
  margin-bottom: 8px;
}

.cm-modal-wrap .form-control-plaintext {
    font-size: 14px;
    font-weight: 700;
    color: #005195;
}

.cm-modal-wrap .form-group:nth-child(2) {
  margin-bottom: 34px;
}

.cm-modal-wrap .form-label {
  font-weight: 500;
  color: #343a40;
  font-size: 12px;
}

.cm-modal-wrap textarea {
  min-height: 101px;
}

.cm-modal-wrap .button {
    margin-top: 18px;
    margin-bottom: 10px;
    font-size: 14px;
    padding-top: 8px;
    padding-bottom: 8px;
    letter-spacing: 1px;
    padding-right: 25px;
    padding-left: 25px;
}

.cm-modal-wrap input::placeholder,
.cm-modal-wrap textarea::placeholder  {
    color: #00529562;
    font-style: oblique;
}

.cm-modal-wrap .form-label {
    font-weight: 500;
    color: #343a40;
    font-size: 12px;
  }
  

@media(max-width:767px){
    .cm-modal-wrap  .form-row>DIV {
        FLEX-BASIS: AUTO;
    }
}