.product-discription {
  .discription {
    .title_bar {
      a {
        color: #000;
        font-size: 16px;
      }
    }
    .tab-content {
      width: 100%;
      .discription_content {
        width: 100%;
        display: flex;
        padding-top: 20px;
        @media (max-width: 767px) {
          flex-direction: column;
        }

        &.details {
          display: flex;
          flex-direction: column;

          .details_content {
            // display: flex;

            @media (max-width: 767px) {
              flex-direction: column;
            }
            ul {
              width: 50%;
              margin-right: 50px;
              @media (max-width: 767px) {
                width: 100%;
              }
              li {
                color: #aaa;
                padding: 5px 15px 0;
                margin-bottom: 10px;
                text-transform: capitalize;
                font-size: 16px;
                // border: 1px solid;

                &.title {
                  color: #000;
                  font-weight: bold;
                  font-size: 16px;
                  margin-bottom: 10px;
                  padding-left: 0;
                  border: 0;
                }
                span {
                  &:first-child {
                    padding-right: 10px;
                    font-weight: bold;
                  }
                }
              }
            }
          }
          .related_img {
            // display: flex;

            @media (max-width: 767px) {
              flex-direction: column;
            }
            .img {
              text-align: center;
              img {
                // width: 100%;
                max-width: 90%;
                height: 300px;
                padding: 10px;

                @media (max-width: 767px) {
                }
              }
            }
            p {
              font-size: 16px;
              color: #aaa;
              padding-top: 10px;
            }
          }
        }
        &.quetion_ans {
          .ask_quetion {
            padding-bottom: 20px;
            @media (min-width: 676px) {
              max-width: 767px;
            }
            .input-group {
              .btn-outline-secondary {
                padding-left: 30px;
                padding-right: 30px;
                background: #aaa;
                color: #fff;
              }
            }
          }
          display: flex;
          flex-direction: column;
          ul {
            margin: 0;
            width: 100%;
            li {
              border: 0;
              margin-bottom: 15px;
              p {
                margin: 0;
                margin-bottom: 5px;

                strong {
                  color: #000;
                  padding-right: 5px;
                }
              }
            }
          }
        }
        &.reviews {
          display: block;

          .start {
            p {
              display: flex;
              position: relative;
              flex-direction: column;
              text-align: center;
              svg {
                width: 110px;
                height: 110px;
                fill: #fdb92c;
              }
              span {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 42px;
                font-size: 17px;
                font-weight: bold;
                color: #fff;
              }
            }
            p {
              width: 100%;
              text-align: left;
            }
          }
          .review_title {
            padding-bottom: 20px;
            p {
              font-size: 16px;
            }
          }
          .rating_wrap {
            border-right: 2px dotted #a9a9a9;
            display: flex;
            justify-content: center;

            @media (max-width: 991px) {
              border-bottom: 2px dotted #a9a9a9;
              border-right: 0;
            }
            .row {
              width: 100%;
            }
            .reting {
              .reting_inner {
                display: flex;
                align-items: center;
                .undefined {
                  span {
                    color: #fdb92c !important;
                  }
                }
                span {
                  padding-left: 10px;
                }
              }
            }
          }
          .share_rating {
            padding-left: 25px;

            @media (max-width: 991px) {
              padding: 0;
              padding: 15px;
            }
            input {
              border: 1px solid #aaa;
            }
            p {
              font-size: 16px;
            }
            .share_rating_button {
              display: flex;
              padding-top: 20px;
              align-items: center;
              span {
                .react-stars {
                  span {
                    font-size: 30px !important;
                    margin-right: 5px;
                  }
                }
              }
              button {
                padding: 10px 30px;
                border-radius: 5px;
                margin-left: 10px;

                @media (max-width: 575px) {
                  padding: 10px 10px;
                }
              }
            }
          }

          .reting_bar {
            display: flex;
            width: 100%;
            justify-content: space-between;
            border-top: 2px dotted #a9a9a9;
            border-bottom: 1px solid #a9a9a9;
            margin-top: 30px;

            h5 {
              font-size: 20px;
              font-weight: 400;

              @media (max-width: 575px) {
                font-size: 12px;
              }
            }
            .filter {
              display: flex;
              align-items: center;
              span {
                padding-right: 10px;
                @media (max-width: 575px) {
                  font-size: 12px;
                }
              }
              .dropdown {
                button {
                  padding: 4px 6px;
                  border-radius: 4px;
                }
              }
            }
          }
          .customre_review {
            padding-top: 20px;
            .row {
              margin-top: 30px;
              padding-bottom: 10px;
              .review_writer {
                display: flex;
                flex-direction: column;
                span {
                  margin-bottom: 5px;
                  color: #3da022;
                  &.name {
                    font-size: 16px;
                    color: #000;
                  }
                }
                date {
                  margin-bottom: 20px;
                }
              }

              .review_text {
                .like {
                  span {
                    margin-right: 10px;
                  }

                  button {
                    padding: 5px 20px;
                    margin-left: 10px;
                    background: transparent;
                    border: 1px solid #aaa;
                    border-radius: 3px;

                    svg {
                      width: 30px;
                      height: 22px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

select.sortingRating {
  padding: 5px;
  font-size: 14px;
}

select.sortingRating:focus {
  outline: none;
}

.tab-content>.active.reviews-wrap {
  height: 1000px;
  overflow: hidden;
  overflow-y: scroll;
} 