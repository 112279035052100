.product-feedback-main-wrap {
  padding-top: 30px;
  padding-bottom: 50px;
  color: #212529;
  background-color: #f3f5f9;
}

.product-feedback-inner {
  max-width: 1140px;
  margin: 0 auto;
}

.pf-header-wrap {
  text-align: center;
  color: #005195;
}

/* .pf-header-wrap h2:after {
    content: '';
    background-color: #005195;
    height: 3px;
    width: 59%;
    position: absolute;
    bottom: -1px;
    left: 3px;
} */

h2.pf-header {
  position: relative;
  display: inline-block;
  font-weight: 500;
  margin-bottom: 5px;
}
.pf-header-wrap p {
  color: #212529;
}

.pf-form {
  max-width: 520px;
  margin: 0 auto;
  margin-top: 34px;
}

.pf-form-category {
  margin-bottom: 20px;
}

.pf-form-category label {
  display: block;
  font-size: 12px;
  margin: 0;
  font-weight: 500;
  color: #005195;
  color: #212529;
}

.pf-form-category select {
  cursor: pointer;
  border: 1px solid grey;
  transition: all 0.15s linear;
  margin-bottom: 0;
  display: block;
  width: 100%;
  padding: 5px;
}

.pf-form-category select:focus {
  outline: none;
  border: 1px solid #00448d;
  box-shadow: 0px 0px 2px 0px #00448d5c;
}

.pf-form-category select option:hover {
  background-color: red;
}

.pf-form-category select option {
  font-size: 16px;
  margin: 10px;
  display: block;
  position: relative;
}

.pf-no-products {
  max-width: 520px;
  margin: 0 auto;
  position: relative;
}

.pf-no-products img {
  width: 100%;
}

.pf-products-wrap {
  margin-top: 34px;
}

.pf-no-products {
  max-width: 520px;
  margin: 0 auto;
  position: relative;
}

.pf-no-products img {
  width: 100%;
}

.pf-products-wrap {
  margin-top: 34px;
}

.pf-products-items {
  display: flex;
  flex-wrap: wrap;
  justify-content:  center;
}

.pf-product-item-img {
  padding: 10px;
  padding: 10px;
  max-width: 125px;
  margin: 0 auto;
  max-height: 125px;
  font-size: 8px;
}

.pf-product-item {
  border: 1px solid #0000007a;
  margin-top: 10px;
  text-align: center;
  padding: 5px;
  background-color: #fff;
  cursor: pointer;
}

.pf-product-item-img img {
  width: 100%;
  max-width: 125px;
  max-height: 125px;
}

.pf-product-item h6 {
  font-size: 10px;
  color: #005195;
  margin-top: 14px;
}

.pf-modal-from .modal-title.h4 {
  color: #005195;
}

.pf-modal-from .modal-header {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pf-form-modal input,
.pf-form-modal textarea {
  border: 1px solid grey;
  transition: all 0.15s linear;
  margin-bottom: 0;
  display: block;
  width: 100%;
  padding: 5px;
  min-height: 30px;
  font-size: 14px;
  width: 100%;
}

.pf-form-modal input:focus,
.pf-form-modal textarea:focus {
  outline: none;
  border: 1px solid #00448d;
  box-shadow: 0px 0px 2px 0px #00448d5c;
}

.pf-form-modal label {
  display: block;
  font-size: 12px;
  margin: 0;
  font-weight: 500;
  color: #005195;
  color: #212529;
}

.pf-form-modal {
  margin-top: 15px;
}

.pf-form-modal {
  margin-top: 15px;
}

.pf-form-modal-img img {
  max-width: 100%;
  height: 100%;
}

.pf-form-modal-img {
  height: 81px;
  text-align: center;
}

.pf-form-modal.rating span {
  cursor: pointer !important;
}
.pf-form-modal.rating label {
  margin-bottom: -5px;
}

.pf-no-products h5 {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  text-align: center;
  font-size: 18px;
}

label.pf-product-title {
  width: 100%;
  max-width: 520px;
  margin: 0 auto;
  display: block;
  display: block;
  font-size: 12px;
  font-weight: 500;
  color: #005195;
  color: #212529;
  margin-bottom: 5px;
  text-align: center;
}
body .modal-backdrop.show {
  opacity: .8;
}

body .modal-backdrop {
  background-color: #0a1938;
}



.product-feedback-main-wrap .pagination-wrap {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 35px;
}
@media (min-width: 320px) and (max-width:639px) {
  .pf-product-item:not(:nth-child(3n)) {
    margin-right: 10px;
  }

  .pf-product-item {
    width: calc(33.33% - 10px);
  }
}

@media (min-width: 640px) and (max-width:767px) {
  .pf-product-item:not(:nth-child(4n)) {
    margin-right: 10px;
  }

  .pf-product-item {
    width: calc(25% - 10px);
  }
}

@media (min-width: 768px) and (max-width:991px) {
  .pf-product-item:not(:nth-child(5n)) {
    margin-right: 10px;
  }

  .pf-product-item {
    width: calc(20% - 10px);
  }
}

@media (min-width: 992px) and (max-width:1023px) {
  .pf-product-item:not(:nth-child(6n)) {
    margin-right: 10px;
  }

  .pf-product-item {
    width: calc(16.6666666667% - 10px);
  }
}

@media (min-width: 1024px) {
  .pf-product-item:not(:nth-child(8n)) {
    margin-right: 10px;
  }

  .pf-product-item {
    width: calc(12.5% - 10px);
  }
}

@media (max-width: 570px) {
  .pf-form {
    padding-left: 15px;
    padding-right: 15px;
  }

  .pf-products-wrap,
  label.pf-product-title  {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 450px) {
  .pf-no-products h5 {
    font-size: 14px;
  }
}
