.frequently_buy {
  background: #f3f5f9;
  .slider_title {
    padding-left: 0;
    padding-right: 0;
  }
  .frequently_buy_card_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    font-size: 20px;
    padding-bottom: 20px;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .frequently_buy_card {
      max-width: 180px;

      @media (max-width: 767px) {
        max-width: 250px;
      }
      a {
        color: inherit;
        text-decoration: none;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 10px 0px;
        background: #fff;
        // border: 1px solid;
        box-shadow: 0px 1px 4px #000;

        &:hover {
          transition: all 1s ease-out;
          transform: scale(1.02);
          box-shadow: 0px 3px 9px #005195 !important;
        }
        img {
          max-width: 90%;
          margin: auto;
          height: 150px;
          object-fit: contain;
          @media (max-width: 767px) {
            height: 190px;
          }
        }
        span {
          width: 100%;
          font-size: 15px;
          padding-top: 10px;
        }
      }
      .frequently_buy_card_price {
        font-size: 16px;
        padding-top: 5px;
        letter-spacing: 1px;
        display: flex;
        del {
          color: #aaa;
          padding-right: 10px;
          display: flex;
          align-items: center;
        }
        span {
          color: #ef0000;
          display: flex;
          align-items: center;
        }
      }
    }
  }
  .aad_to_card {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 991px) {
      padding-bottom: 20px;
      padding-top: 20px;
    }
    .total_price {
      padding-bottom: 10px;
      text-align: left;
      font-size: 18px;
      text-transform: capitalize;

      span.Amount {
        width: 100%;
        color: #ef0000;
        padding-left: 10px;
        font-weight: 500;
      }
    }
  }

  .label_check {
    margin-top: 20px;
    form {
      .mb-3 {
        display: flex;
        flex-direction: column;
        .form-check-inline {
          padding-bottom: 15px;
          font-size: 16px;
          text-transform: capitalize;
          label {
            padding-left: 10px;
          }
        }
      }
    }
  }
}
