.shipping-address-wrap .PlacedOrder button {
  display: inline-block;
  width: auto;
  text-align: center;
  padding: 10px 20px;
  color: #fff !important;
  border-radius: 3px;
  font-size: 15px;
  margin-top: 0;
  background: #00448d;
  transition: 0.3s ease-out;
  border: #ffdead;
  border-radius: 40px;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.shipping-address-wrap {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #f3f5f9;
}

.shipping-address-wrap .top-header h1 {
  font-size: 25px;
  line-height: 1.2;
  color: #343a40;
}

.shipping-address-wrap .shiping-notice {
  padding: 10px;
  margin-top: 15px;
  margin-bottom: 25px;
  background-color: #fff;
  border: 1px solid #ebebeb;
  border-left: 3px solid #005195;
}

.shipping-address-wrap .shiping-notice p {
  font-size: 15px;
  line-height: 1.2;
  margin-bottom: 0;
  color: #000;
  letter-spacing: 0.2px;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;

  &:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 3px rgba(13, 110, 253, 0.25);
  }
}

.shipping_address_form {
  .card {
    background: transparent;
    border: none;
    .card-header {
      .btn.btn-link {
        .form-check {
          padding-left: 0;
          .form-check-input {
            position: static;
            margin-right: 10px;
          }
        }
      }
    }
    .card-header {
      background-color: transparent;
      border: none;
    }
  }
}
