.review-main-wrap .ca-top-section.os-top-section h1 {
    margin-bottom: 5px;
}

.review-main-wrap .review-order-wrap-outer {
    border: 1px solid #ebebeb;
    background-color: #fff;
    padding: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: #fff;
    overflow: hidden;
}

.review-main-wrap .order-summary-wrap.row-wrap {
    margin-top: 84px;
}

.review-main-wrap .row-top-section h4 {
    margin-bottom: 10px;
    font-size: 20px;
}

.review-main-wrap .row-top-section h5 {
    font-size: 14px;
}

.review-main-wrap  .row-top-section:not(:first-child) {
    margin-top: 35px;
}

.review-main-wrap  .row-top-section p {
    margin-bottom: 0;
}

.review-main-wrap  .row-top-section h5 {
    margin-bottom: 0;
}



 .review-main-wrap .row-top-section h4 {
    margin-bottom: -3px;
    color: #00448d;
}

.review-order-wrap-cart {
    overflow-y: scroll;
    max-height: 362px;
}

.cart-page-wrap .sc-item-wrap {
    margin-top: 0;
    margin-bottom: 0;
}

.cart-page-wrap .sc-item-wrap:not(:first-child) {
    border-top: 0;
}

.review-order-wrap-cart h4 {
    margin-top: 20px;
}
.review-order-wrap-cart {
  overflow-y: scroll;
  max-height: 450px;
}

.review-order-wrap-cart:hover::-webkit-scrollbar-thumb {
  background: linear-gradient(#0051955c, transparent);
}
.review-order-wrap-cart::-webkit-scrollbar {
  width: 5px;
}

.review-order-wrap-cart::-webkit-scrollbar-thumb {
  background: linear-gradient(transparent, transparent);
}

.ca-top-section.os-top-section.middle-section h1 {
    margin-top: 32px;
    margin-bottom: 5px;
    color: #00448d;
}

.payment-options-wrap {
    margin-top: 30px;
}

.po-inner {
    position: relative;
}


.po-inner input[type="radio"] {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
}

.po-inner h5 {
    font-size: 16px;
    padding-left: 30px;
}

.po-item {
    margin-bottom: 16px;
}
.checkout.danger button {
    background-color: #ff00004f;
}

.address-edit-remove.os-1 {
    justify-content: flex-end;
    top: -28px;
    right: -4px;
}

.row-top-section {
    position: relative;
}

p.cm-warn {
    color: red;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 500;
}