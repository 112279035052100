.bulk-item .bulk-content-wrap {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.bulk-item .content-col {
    width: 75%;
}

.bulk-item .btn-col {
    width: 25%;
    display: flex;
    flex-direction: column;
}

.bulk-item .bulk-wrap {
    margin-top: 20px;
}

.bulk-item {
    border-top: 1px solid #aaa;
}

.bulk-item ul.content-col>li>small {
    font-weight: bolder;
}

.bulk-item ul.content-col>li>p {
    margin-bottom: 10px;
}

.bulk-wrap {
    margin-top: 10px;
}

.bulk-item:first-child {
    border: none;
    margin-bottom: 10px;
}

.cm-btn {
    outline: none;
    border: none;
    margin: 0 5px;
    padding: 4px 10px;
    border-radius: 2px;
    font-size: 12px;
    box-shadow: 0px 0px 3px #aaa;
    background-color: #fff;
    font-weight: 700;
    text-decoration: none;
    text-align: center;
    color: #343a40;
    transition:all .2s linear;
}

.cm-btn:not(:last-child) {
    margin-bottom: 10px;
}

.cm-btn:hover {
    text-decoration: none;
    color: #343a40;
}

span.cm-btn.request_status {
    border-radius: 20px;
    padding: 3px 10px;
    width: auto;
    margin-left: 0;
    margin-right: 0;
    min-width: unset;
    font-style: italic;
    box-shadow: none;
    color: #343a40;
    background-color: #ffbe176b;
    position: relative;
    top: -1px;
}


.cm-btn:hover {
    box-shadow: 0px 0px 3px #005195;
    background-color: #005195;
    color: #fff;
}

.cm-btn.cancel-request:hover {
    box-shadow: 0px 0px 3px #fb0700ed;
    background-color: #fb0700ed;
    color: #fff;
}

a.productName {
    text-decoration: none;
    color: #005195;
    font-size: 16px;
    font-weight: 500;
}

a.productName:hover {
    text-decoration: none;
}


.pagination-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
}

.pagination-wrap>div {
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    
}

.prev-page>svg,.next-page>svg {
    font-size: 20px;
}

.prev-page>svg >polygon, .next-page>svg>polygon {
    fill: #005195;
}

.prev-page> span:hover, .next-page> span:hover {
    color: #005195;
}

span.firstPage.active {
    color: #0a368d;
}
.prev-page {
    background-color: #f3f4f669;
    padding: 5px 13px;
    border-radius: 0px;
}

.next-page {
    background-color: #f3f4f669;
    padding: 5px 13px;
    border-radius: 0px;
}

span.bulk-request-date {
    font-size: 12px;
}

@media(max-width:540px){
    .bulk-item .btn-col {
        flex-direction: row;
        width: 100%;
        margin-bottom: 15px;
    }
    
    .bulk-item .content-col {
        width: 100%;
    }
    
    .bulk-item .bulk-content-wrap {
        flex-wrap: wrap;
    }
    
    .cm-btn {
        width: 50%;
    }
    
    .cm-btn:not(:last-child) {
        margin-bottom: 0;
    }
        
}