.range_slider_inner {
  margin-top: 45px;
  width: 100%;
}
.noUi-horizontal .noUi-handle-upper .noUi-tooltip { 
  bottom:-32px
}
.noUi-horizontal .noUi-tooltip {
  bottom:-32px !important
}
.noUi-connect {
  background:#015195 !important;
}