.addtocart_inner {
  // border-top: 1px solid #aaa;
  padding-top: 30px !important;
  .item_img_wrap {
    @media (max-width: 767px) {
      padding: 40px 0;
    }
    .item_img {
      position: relative;
      img {
        width: 100%;
        max-width: unset;
      }
      span {
        position: absolute;
        top: 0;
        left: 0;
        padding: 17px;
        background: #f84819;
        border-radius: 45px;
        width: 65px;
        height: 65px;
        padding-top: 20px;
        padding-left: 15px;
        font-size: 16px;
        font-weight: bold;
        color: #fff;
      }
    }

    .image_slider {
      margin-top: 15px;
      width: auto;

      .owl-carousel {
        position: relative;
        .owl-stage-outer {
          z-index: 999;
        }
        .owl-nav {
          .owl-prev {
            position: relative;
            z-index: 9999;
            right: 14px;
          }
          .owl-next {
            position: relative;
            z-index: 9999;
            left: 14px;
          }
        }
        .item {
          z-index: 999999;
          img {
            width: 100%;
            max-width: 100px;
            height: 75px;
          }
        }

        .owl-nav {
          position: absolute;
          width: 100%;
          top: 50%;
          transform: translateY(-50%);
          display: flex;
          justify-content: space-between;
          margin-top: 0;
          button {
            margin: 0 !important;
            &:hover {
              background: transparent;
            }
            span {
              font-size: 50px;
              color: #f84819;
            }
          }
        }
      }
    }
  }

  .item_content {
    h5 {
      padding-top: 0;
      font-size: 18px;
      font-weight: 500;
      padding-bottom: 10px;
    }
    p {
      margin-bottom: 20px;
      span {
        margin-left: 10px;
        // font-size: 15px;

        &:first-child {
          margin-left: 0;
        }
        &.star {
          background: #5bac61;
          padding: 2px 10px;
          border-radius: 3px;
          color: #fff;
          font-weight: 500;
          font-size: 16px;
        }
        &.stock {
          font-weight: 700;
          color: #5bac61;
          text-transform: uppercase;
          font-size: 16px;
        }
        &.buy {
          background: #f8d6d7;
          font-size: 12px;
          padding: 8px 20px;
          color: #b70000;
          margin-bottom: 15px;
          display: inline-block;
          border-radius: 5px;
        }
        &.offer_price {
          font-size: 24px;
          margin-right: 10px;
          vertical-align: middle;
        }
        &.gst {
          display: block;
          font-size: 15px;
          margin-left: 0 !important;

          svg {
            font-size: inherit;
          }
        }
      }
      .old_price {
        margin-right: 10px;
      }
      small {
        font-size: 12px;
        color: #5bac61;
      }

      &.about_product {
        display: flex;
        flex-wrap: wrap;
        span {
          border: 1px solid #333333;
          padding: 2px 5px;
          font-weight: 500;
          cursor: pointer;
          margin-bottom: 10px;
          &:hover {
            color: #ef0000;
          }
          &:first-child {
            display: block !important;
            background: transparent;
            border: none;
            padding: 0;
            cursor: text;
            padding-bottom: 5px;
            width: 100%;
            margin: 0;
            &:hover {
              color: inherit;
            }
          }
          &:nth-child(2) {
            margin-left: 0;
          }
          &.selected {
            background: #333333;
            color: #fff;
          }
        }
      }
    }

    .detail {
      margin-bottom: 10px;
      p {
        margin-bottom: 5px;
      }
    }
    span {
      &.buy {
        background: #f8d6d7;
        font-size: 12px;
        padding: 8px 20px;
        color: #b70000;
        margin-bottom: 15px;
        display: inline-block;
        border-radius: 5px;
      }
    }
    .button_section {
      display: flex;

      @media (max-width: 575px) {
        flex-direction: column;
      }
      .cart_button {
        font-size: 16px;
        font-weight: 500;
        word-spacing: 2px;
        border-radius: 4px;
        width: 46%;
        padding-left: 25px;
        padding-right: 25px;

        @media (max-width: 575px) {
          width: auto !important;
          font-size: 14px;
          padding: 10px;
          border: 1px;
          margin: 0 5px;
        }
      }
      .buy_now {
        background: #5ba621;
        border-color: #5ba621;
        margin-left: 20px;

        @media (max-width: 575px) {
          margin-left: 0;
        }

        &:hover {
          background: #4c8a1c;
          border-color: #4c8a1c;
        }
      }
    }
  }

  .bulk_order {
    @media (max-width: 991px) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    .check_delivery {
      h6 {
        font-size: 12px;
      }
      .input-group {
        width: 90%;

        @media (max-width: 991px) {
          width: 100%;
        }
        .form-control {
          font-size: 14px;
        }
        button {
          background-color: #676767;
          color: #fff;
          font-size: 14px;
        }
      }
      p {
        font-size: 12px;
        color: #aaa;
      }
    }
    .get_bulk {
      padding: 15px 10px;
      border: 1px dashed #ccc;
      background: rgba(251, 221, 222, 0.31);
      p {
        font-size: 13px;
        margin-bottom: 10px;
        text-align: center;
        color: #000;
        font-weight: 500;
      }
      .button {
        width: 100%;
        padding: 10px 20px;
        margin-top: 0;
        outline: none;
        font-size: 15px;
        margin-bottom: 5px;

        a {
          color: #fff;
          text-decoration: none;
        }
      }
    }
    .offers {
      margin-top: 15px;
      padding: 25px 10px 15px;
      border: 1px dashed #ccc;
      background: rgba(251, 221, 222, 0.31);
      p {
        font-size: 13px;
        font-weight: 500;
      }
    }
  }
}

.addtocart_inner .item_content p span.stock.outofstock {
  color: red;
}

.addtocart_inner .item_content .button_section > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addtocart_inner .item_content .button_section .cart_button {
  width: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

.addtocart_inner .item_content .button_section > div .si-qty-count {
  margin-right: 20px;
}

.addtocart_inner .item_content .button_section > div .si-qty-count > span {
  font-size: 16px;
  padding: 5px 8px;
}
.check_delivery_wrap {
  color: green;
  font-size: 14px;
  font-weight: 500;
  margin-left: 3px;
}

.check_delivery_wrap.error {
  color: red;
}

p.check-quantiy {
  margin-top: 8px;
  color: red;
}

.cm-button-style.addtocart_inner .item_content .button_section > div {
  width: 100%;
  // justify-content: flex-start;
  margin-bottom: 10px;
}

.cm-button-styl.addtocart_inner .item_content .button_section .cart_button {
  width: 100%;
  margin-left: 0;
}

.cm-button-style.addtocart_inner .item_content .button_section {
  flex-wrap: wrap;
}

.cm-button-style.addtocart_inner .item_content .button_section > div .si-qty-count {
  width: 25%;
  margin-right: 0;
  height: 100%;
  display: flex;
}

.cm-button-style.addtocart_inner .item_content .button_section > div .si-qty-count + button {
  width: 50%;
  margin-right: 8px;
  margin-left: 8px;
}

.cm-button-style .product_icon.product-page {
  margin-top: 0;
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: #f3f4f6;
  transition: background-color 0.15s ease-in-out;
  border: 1px solid #e4e5e5;
}

.cm-button-style .product_icon.product-page .wishlist {
  margin-right: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  flex-direction: column;
}

.cm-button-style.addtocart_inner .item_content .button_section > div .si-qty-count > span {
  width: 33.33%;
  text-align: center;
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cm-button-style .product_icon.product-page .wishlist .cm-add-to-wishlist {
  font-size: 11px;
  padding: 0;
}

.addtocart_inner .item_content .button_section .cart_button {
  width: 100%;
  margin-left: 0;
}

.cm-button-style .product_icon.product-page span {
  box-shadow: none;
}

.cm-button-style .product_icon.product-page span:hover {
  background-color: #f3f4f6;
}

button.cart_button.buy_now.out-of-stock {
  opacity: 0.8;
  cursor: not-allowed;
}

.cm-button-style .product_icon.product-page.out.of.stock {
  background-color: transparent;
  width: auto;
  border: none;
  margin-bottom: 10px;
}
.cm-button-style .product_icon.product-page.out.of.stock .wishlist:hover {
  background-color: transparent;
}

.cm-button-style .product_icon.product-page.out.of.stock .wishlist {
  flex-direction: row;
  justify-content: flex-start;
}

.cm-button-style .product_icon.product-page.out.of.stock .wishlist svg {
  margin-right: 10px;
}

p.add-to-compare {
  font-weight: 500;
  cursor: pointer;
  display: inline-block;
  background: #e4e5e5;
  border-radius: 3px;
  padding: 5px 10px;
}

p.add-to-compare:hover {
  background-color: #f8f9faa3;
  color: #0a368d;
}

@media (max-width: 575px) {
  .cm-button-style.addtocart_inner .item_content .button_section > div .si-qty-count > span {
    height: 38px;
  }
  .cm-button-style .product_icon.product-page .wishlist {
    text-align: center;
  }
}

.addtocart_inner .item_content p span.gst svg {
  font-size: 15px;
}

.addtocart_inner .item_content p .old_price svg {
  font-size: 15px;
}
.addtocart_inner .item_content p span.star svg {
  font-size: 15px;
}
.addtocart_inner .item_content p span.gst-price {
  font-weight: 500;
}

.product-variant-wrap {
  margin-bottom: 15px;
}

.p-variant-item h6 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
}

.p-variant-item:not(:last-child) {
  margin-bottom: 13px;
}

.p-variant- span {
  font-size: 12px;
  padding: 5px;
  border: 1px solid #000;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.15s linear;
}

.p-variant- span {
  margin-left: 5px;
  display: inline-block;
  margin-bottom: 5px;
}

.p-variant- span.active,
.p-variant- span.selected {
  background-color: #000;
  color: #fff;
}

.p-variant- span:hover {
  background-color: #aaaaaa66;
  border-color: grey;
}

.p-variant- span.active:hover,
.p-variant- span.selected:hover {
  background-color: #000;
  color: #fff;
  border-color: #000;
}
small.varientname {
  margin-left: 5px;
  font-weight: 500;
  color: #005195;
  font-size: 12px;
  font-style: italic;
}
