body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.skiptranslate.goog-te-gadget {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 0;
}

.goog-te-gadget .goog-te-combo {
  margin: 0;
  background-color: transparent;
  color: #fff;
  border: none;
  margin-top: 2px;
}

.navbar-expand-lg .navbar-nav {
  padding-right: 25px;
}

.skiptranslate.goog-te-gadget span {
  display: none;
}

.skiptranslate.goog-te-gadget>div {
  font-size: 12px;
}

.goog-te-gadget .goog-te-c option {
  color: #000;
}

.goog-te-gadget .goog-te-combo option {
  color: #000;
}

@media (max-width: 1400px) {
  .skiptranslate.goog-te-gadget {
    right: 20%;
  }
}

@media(min-width: 991.2px) {
  .top_header .container {
    max-width: 100%;
    padding: 0 40px;
  }

  .skiptranslate.goog-te-gadget {
    right: 140px;
  }

  .navbar-expand-lg .navbar-nav {
    padding-right: 0px;
  }
}


@media(max-width:991px) and (min-width: 767px) {
  .skiptranslate.goog-te-gadget {
    top: 11px;
    right: 25%;
  }
}



@media(max-width:767px) {
  .goog-te-gadget .goog-te-combo:focus {
    border: none;
  }

  .goog-te-gadget .goog-te-combo {
    color: #000;
    background-color: #0a368d05;
  }

  .goog-te-gadget .goog-te-combo option {
    color: #000;
  }

  .skiptranslate.goog-te-gadget {
    position: absolute;
    top: 0px;
    right: 10px;
    font-size: 0;
    right: 0px;
    z-index: 9999;
    width: 164px;
  }

}