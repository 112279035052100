$logoColor: #16438d;
$navbarColor: #000;
$whiteColor: #fff;

.my-order {
  .savedProduct {
    .my-heading {
      padding: 0.75rem 1.25rem;
      margin-bottom: 0;
      background-color: #f3f4f6;
      border-bottom: 1px solid #f3f4f6;
      font-weight: 600;
      font-size: 18px;
    }
    table {
      font-size: 14px;
      thead {
        border: 1px solid #ddd;
      }
      tbody {
        tr {
          border: 1px solid #ddd;
          td {
            vertical-align: baseline;
            @media (max-width: 575px) {
              padding: 10px 6px;
            }
            &.price {
              @media (max-width: 575px) {
                display: flex;
              }
            }
            img {
              max-width: 40px;
              max-height: 40px;
            }
          }
        }
      }
    }
  }
  .pagination {
    border: none;
  }
}

.product-detail {
  .my-heading {
    padding: 8px 20px;
    margin: 20px 0px;
    background-color: #f3f4f6;
    border-bottom: 1px solid #f3f4f6;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    color: #16438d;
    justify-content: space-between;
    button {
      background: #16438d;
      color: white;
      font-size: 14px;
      border: none;
      padding: 4px 8px;
    }
  }
  table {
    font-size: 12px;
    thead {
      border: 1px solid #ddd;
    }
    tbody {
      tr {
        border: 1px solid #ddd;
        td {
          vertical-align: baseline;
        }
      }
    }
  }
  .order-detail {
    font-size: 14px;
    padding: 0 4px;
    .order-row {
      display: flex;
      flex-wrap: wrap;
      padding: 6px 0px;
      border-bottom: 1px solid #dee2e6;
    }
  }
  .address-detail {
    .address-row {
      font-size: 14px;
      padding: 20px 0;
      p {
        font-size: 12px;
        line-height: 5px;
      }
    }
  }
}
