.choose-address-wrap {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #f3f5f9;
}
.choose-address-wrap {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #f3f5f9;
}

.ca-top-section h1 {
  font-size: 25px;
  line-height: 1.2;
  color: #343a40;
  margin-bottom: 15px;
}

.ca-saved-addresses {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-start;
}

.ca-saved {
  padding: 15px;
  border: 1px dashed #00519561;
  position: relative;
  background-color: #fff;
  margin-right: 20px;
  min-width: 220px;
  width: calc(25% - 15px);
  margin-bottom: 20px;
  -webkit-animation: pop-in 0.4s;
  -moz-animation: pop-in 0.4s;
  -ms-animation: pop-in 0.4s;
  transition: all 0.25s linear;
}

.ca-new-saved.ca-saved img {
  max-width: 120px;
}

.ca-new-saved.ca-saved h5 {
  text-align: center;
  text-align: center;
  font-size: 18px;
}

.ca-new-saved.ca-saved {
  text-align: center;
  min-height: auto;
  cursor: pointer;
  width: 100%;
  display: block;
}

.ca-new-saved.ca-saved:hover {
  text-decoration: none;
}
.ca-saved-items.ca-saved:nth-child(4n) {
  margin-right: 0;
}

.ca-saved:hover {
  box-shadow: 1px 1px 12px #00519561;
}

.ca-saved:hover {
  box-shadow: 1px 1px 12px #00519561;
}

.ca-saved-items p {
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 14px;
}

.delivery-btn.wrap .button {
  display: inline-block;
  font-size: 14px;
  padding: 8px;
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
  line-height: normal;
  margin-top: 10px;
}

.delivery-btn.wrap .button:hover {
  color: #fff;
  transition: background-color 0.3s ease-out;
  background: #00448d;
  text-decoration: none;
}

.address-edit-remove {
  right: 15px;
  bottom: 8px;
  width: 100%;
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
}

.address-edit-remove span {
  text-align: right;
  cursor: pointer;
  transition: all 0.23s ease-in-out;
  font-size: 12px;
  color: #212529;
}

.address-edit-remove span:first-child {
  margin-right: 10px;
}

.address-edit-remove span:first-child:hover {
  color: green;
}

.address-edit-remove span:hover {
  color: #f5391ac9;
}

.ca-saved h5 {
  margin-top: 0;
}

.address-choice>div label {
  margin-left: 8px;
  font-weight: 500;
}

.address-choice {
  margin-top: 21px;
  position: absolute;
  bottom: 34px;
}

.ca-saved-items {
  height: 260px;
}

.address-choice>div input[type="checkbox"] {
  border: 2px!important;
}

.address-choice p {
  color: #0ec90e;
  font-weight: 500;
  line-height: 1.8;
}

.gst-form-inner {
  margin-bottom: 30px;
}

.address-choice p svg {
  font-size: 18px;
  margin-right: 5px;
}
.form-wrap.hide {
    transform: scale(0);
    height: 0;
}

.form-wrap {
    transition: all 0.3s ease-in-out;
}

.form-wrap.show {
    transform: scale(1);
}


.addressform .form-check-label {
    margin-left: 10px;
}

.addressform input[type="checkbox"], input[type="radio"] {
    position: relative;
    top: 1px;
}

.addressform input[type="checkbox"], input[type="radio"] {
    position: relative;
    top: 1px;
}

.addressform  .button {
    margin-top: 10px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.addressform  .form-check {
    padding-left: 0;
}

.addressform .form-wrap {
    margin-top: 20px;
    margin-bottom: 20px;
}


.addressform .card-header {
    padding-left: 0;
}

.accordion>.card>.card-header {
    margin-top: -15px;
    margin-bottom: 20px;
}

.addressform .card-header.form-check-label {
    font-size: 18px;
}
.addressform  .accordion>.card>.card-header {
    padding-left: 0;
    margin-bottom: 10px;
}
.addressform  .save {
    margin-bottom: 33px;
}


.addressform .accordion > .card > .card-header button {
    padding-left: 0;
}



.gst-form-main-wrap.no {
  -webkit-animation: pop-in 0.4s;
  -moz-animation: pop-in 0.4s;
  -ms-animation: pop-in 0.4s;
  transition: all 0.25s linear;
  overflow: hidden;
  display: none;
}

.gst-form-main-wrap.yes {
  display: block;
  margin-top: 25px;
  margin-bottom: 15px;
}

.gst-form-wrap {
  padding: 5px 0;
  padding-left: 10px;
  border-left: 3px solid #00448d;
  margin: 20px 0;
}

.gst-form-wrap p {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0;
}

.gst-form-wrap>div {
  margin-top: 10px;
  font-size: 14px;
}


.gst-form-wrap>div label:not(:first-child) {
  margin-right: 10px;
}

.gst-form-wrap>div [type="radio"] {
  font-size: 29px;
  color: #7888a5;
  margin-right: 8px;
}

.gst-form-main-wrap label {
  font-weight: 500;
  font-size: 13px;
}

.gst-form-main-wrap input {
  font-size: 16px;
  line-height: normal;
  padding-left: 10px;
  padding-right: 10px;
  transition: all .25s ease-in-out;
}

.gst-form-main-wrap .input:not(:last-child) {
  margin-bottom: 25px;
}

.gst-form-main-wrap input:focus {
  outline: none;
  box-shadow: 1px 1px 5px 1px #00448d40;
}
@media (max-width:1199px) {
  .ca-saved {
    width: calc(33.33% - 15px);
  }
  .ca-saved-items.ca-saved:nth-child(4n) {
    margin-right: 20px;
  }
  .ca-saved-items.ca-saved:nth-child(3n) {
    margin-right: 00;
  }
}
@media (max-width:991px) {
    .ca-saved {
      width: calc(50% - 20px);
    }
    .ca-saved-items.ca-saved:nth-child(4n) {
      margin-right: 0px;
    }
    .ca-saved-items.ca-saved:nth-child(2n) {
      margin-right: 20px;
    }
    .ca-saved-items.ca-saved:nth-child(3n) {
        margin-right: 20px;
      }
  }

@media (max-width:767px) {
    .ca-saved {
      width: calc(50% - 15px);
    }
    .ca-saved-items.ca-saved:nth-child(4n) {
      margin-right: 20px;
    }
    .ca-saved-items.ca-saved:nth-child(3n) {
      margin-right: 20px;
    }
    .ca-saved-items.ca-saved:nth-child(2n) {
        margin-right: 0;
      }
  }

  @media (max-width:540px) {
    .ca-saved {
      width: calc(100% - 0px);
      margin-right: 0;
    }
    .ca-saved-items.ca-saved:nth-child(4n) {
      margin-right: 0;
    }
    .ca-saved-items.ca-saved:nth-child(3n) {
      margin-right: 0
    }
    .ca-saved-items.ca-saved:nth-child(2n) {
        margin-right: 0;
      }
  }

@-webkit-keyframes pop-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes pop-in {
  0% {
    opacity: 0;
    -moz-transform: scale(0.5);
  }
  100% {
    opacity: 1;
    -moz-transform: scale(1);
  }
}
@keyframes pop-in {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}


.choose-address-wrap.dashboard .ca-saved {
  min-width: 200px;
}