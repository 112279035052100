.civil_lab {
  .row {
    flex-direction: column;
  }
  .civil_lab_slider {
    background: #fff;
    .col {
      padding: 0;
    }
  }

  .lab_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .lab_wrapper_img {
    width: 53%;
    img {
      max-width: 100%;
      min-height: 620px;
      width: 100%;
      @media (max-width: 767px) {
        height: auto;
        min-height: auto;
      }
    }
  }
  .civil_lab_content {
    width: 45%;
    display: flex;
    flex-direction: column;
    .product {
      display: block;
      max-width: 100%;
      background: #fff;
      box-shadow: none;
      border-radius: 0;
      margin: 0;
      // margin-bottom: 2px;
      height: 308px;
      > a {
        img {
          max-width: 430px;
          width: 100%;
        }
      }
    }
    .product_body {
      > p {
        text-align: center;
      }
    }
  }
  section.civil_lab.spacing {
    background: #f3f5f9;
  }
  @media (max-width: 991px) {
    .lab_wrapper {
      flex-direction: column;
    }
    .lab_wrapper_img {
      width: 100%;
    }
    .civil_lab_content {
      width: 100%;
    }
  }
}

.civil_lab .civil_lab_content .product.item0 > a img {
  max-width: 150px;
}
