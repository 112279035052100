.add-to-compare-box-wrap {
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 9999;
  
}

.animate-pop-in{
  -webkit-animation: pop-in 0.4s;
  -moz-animation: pop-in 0.4s;
  -ms-animation: pop-in 0.4s;
}

.addtocart_inner .item_content p svg {
  font-size: 24px;
}
section.addTocart {
  position: relative;
}

.atc-box {
  position: relative;
}

.atc-box {
  border: 2px solid #16438d;
  padding: 10px;
  position: relative;
width: 250px;
  background-color: #fff;
}

.close-icon {
  text-align: right;
  position: relative;
  right: 3px;
  cursor:pointer;
}


.atc-box .atc-items {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding-bottom: 5px;
}

.atc-box .atc-item-images {
  margin-right: 15px;
}

.atc-box .atc-item-images img {
  max-width: 30px;
  width: 100%;
}



.atc-box .atc-items:not(:last-child) {
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.atc-box .atc-item-name {
  margin-right: 15px;
  font-weight: 500;
  font-size: 12px;
}

.atc-box .atc-item-remove svg {
  fill: red;
}

.atc-box a.add-to-compare.button {
  display: inline-block;
  width: 102%;
  text-align: center;
  padding: 5px;
  font-size: 12px;
}

.atc-box a.add-to-compare.button:hover {
  background: #00448d;
  color: #fff;
  text-decoration: none;
  opacity: .95;
}

/* Add to compare */
.add-to-compare-main-wrap {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #f3f5f9;
  
}

.atc-list-inner {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  max-width: 1200px;
  margin: 0 auto;

}

.atc-list-inner-item {
  width: 33.33%;
}

.add-to-compare-main-wrap {
  margin: 0 auto;
}

.atc-list-inner-item .img-wrap {
  text-align: center;
  margin-bottom: 20px;
}

.atc-list-inner-item .img-wrap img {
  width: 150px;
  height:100px;
  transition: all .2s ease-in-out;
  object-fit: contain;
}
.atc-list-inner-item .img-wrap :hover img {
  transform: scale(1.1);
}

.atc-list-inner-item {
  background-color: #fff;
}

.atc-list-inner-item:not(:first-child) {
  border-left: 1px solid grey;
}

.atc-list-inner-item >div {
  margin-bottom: 15px;
}

.atc-list-inner-item >div.price del {
  display: inline-block;
  margin-left: 5px;
  color: #e54545ed;
}

.atc-list-inner-item >div.price h5 {
  display: inline-block;
}

.atc-list-inner-item .name >h5>a {
  color: #005195;
}

.atc-list-inner-item .name >h5>a:hover {
  text-decoration: none;
}

.atc-list-inner-item h5 {
  margin: 0;
}

.atc-list-inner-item >div.desc {
  font-size: 12px;
  text-transform: initial;
}

.atc-list-inner-item >div.add-to-wisthlist-btn {
  display: flex;
}

.atc-list-inner-item >div.add-to-wishlist-btn {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
}

.atc-list-inner-item >div.add-to-wishlist-btn .button {
  margin-top: 5px;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  width: auto;
  padding: 5px 15px;
}

.atc-list-inner-item >div.add-to-wishlist-btn .button:not(:last-child) {
  margin-right: 6px;
}

.atc-list-inner-item >div.additional-info {
  font-size: 12px;
  text-transform: initial;
  margin-top: 35px;
}

.atc-list-inner-item >div.price {
  min-height: auto;
}

.atc-list-inner {
  padding-top: 1px;
}

.top-header-section {
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 25px;

}

.atc-item-remove {cursor: pointer;transition: all .2s linear;}

.atc-item-remove:hover {
  transform: scale(1.1);
}

.atc-list-inner-item div:nth-child(even) {
  background-color: #f3f5f9;
}

.atc-list-inner-item >div.additional-info {
  margin-top: 0;
  margin-bottom: 0;
}

.atc-list-inner-item >div.additional-info h5 {
  padding-top: 0;
}

.atc-list-inner-item >div.additional-info p {
  margin-bottom: 0;
}

.atc-list-inner-item >div.add-to-wishlist-btn {
  margin-top: 0;
  padding-bottom: 15px;
}

.atc-list-inner-item >div {
  margin-bottom: 0;
}

.atc-list-inner-item >div.rating p {
  margin-bottom: 0;
}

.atc-list-inner-item >div.name p {
  margin-bottom: 0;
}

.atc-list-inner-item h5 {
  padding-top: 0;
}

.atc-list-inner-item >div {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.add-to-compare-main-wrap {
  padding-bottom: 0;
}

.add-to-compare-main-wrap .top-header-section h4 {
  font-size: 30px;
  font-weight: 700;
}

.add-to-compare-main-wrap .top-header-section h4 {
  font-size: 30px;
  font-weight: 700;
}

.atc-list-inner-item >div.desc h5 {
  margin-bottom: 8px;
  font-size: 14px;
}

.atc-list-inner-item >div.additional-info h5 {
  margin-bottom: 8px;
  font-size: 14px;
}

.atc-list-inner-item h5 {
  color: #212529d9;
}




@media(max-width:767px){
  .atc-list-inner-item .img-wrap img {
      max-width: 100px;
  }
  
  .atc-list-inner-item h5 {
      font-size: 12px;
  }
  
  .atc-list-inner-item >div {
      padding-right: 10px;
      padding-left: 10px;
  }
  
  .atc-list-inner-item >div.name {
      min-height: 90px;
  }
  
  .atc-list-inner-item >div.desc {
      min-height: 150px;
  }
  
  .atc-list-inner {
      /* min-width: 580px; */
  }
  
 
}


/* atc-search */
.atc-search-box-wrap {
  background-color: #f3f5f9;
  border-left: 1px solid #00000078;
  padding-left: 10px;
  WIDTH: 400PX;
  padding-right: 10px;
}



.atc-search-title H3 {
  font-size: 20px;
  font-weight: 900;
  color: #005195;
  margin-top: 0;
  padding-top: 0;
}

.atc-search-wrap input {
  border: 1px solid #8080806e;
  transition: all .15s linear;
  margin-bottom: 0;
  min-height: 35px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 1px;
}

.atc-search-wrap input:focus {
  outline: none;
  border: 1px solid #00448db0;
  box-shadow: 0px 0px 2px 0px #00448d5c;
}



.atc-search-icon {
  position: absolute;
  width: 35px;
  height: 35px;
  top: 0;
  right: 0;
  cursor: pointer;
}

.atc-search-wrap {
  position: relative;
  margin-top: 15px;
}

.atc-search-icon svg {
  width: 100%;
  height: 100%;
  padding: 10px;
  color: #808080d9;
  transition: all .15s linear;
}

.atc-search-icon:hover svg {
  color: #005195;
}

.atc-search-items {
  margin-top: 20px;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}


.atc-search-item:not(:last-child) {
  border-bottom: 1px solid #e9ecef;
}

.atc-search-item ul {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  margin: 0;
}

.atc-search-item ul .atc-search-img {
  width: 85px;
  height: 85px;
  overflow: hidden;
  padding: 8px;
}

.atc-search-item ul .atc-search-item-content {
  width: 75%;
  padding: 8px;
}

.atc-search-item ul .atc-search-img img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.atc-search-item ul .atc-search-item-content p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
  color: #005195;
}

.atc-search-item ul .atc-search-item-content h6 {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
}

.atc-search-items:hover::-webkit-scrollbar-thumb {
  background: linear-gradient(#0051955c, transparent);
}
.atc-search-items::-webkit-scrollbar {
  width: 4px;
}

.atc-search-items::-webkit-scrollbar-thumb {
  background: linear-gradient(transparent, transparent);
}
.atc-search-item {
  cursor: pointer;
  transition: all .15s linear;
}

.atc-search-item:hover {
  box-shadow: -1px 1px 3px 0px grey;
  transform: translate3d(0px, -1px, 0px) scale(1.01);
}

@-webkit-keyframes pop-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes pop-in {
  0% {
    opacity: 0;
    -moz-transform: scale(0.5);
  }
  100% {
    opacity: 1;
    -moz-transform: scale(1);
  }
}
@keyframes pop-in {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.no-product-found {
  text-align: center;
  color: #ff0000ad;
}

.atc-search-item.animate-pop-in.cm-hide {
  display: none;
}

.atc-list-inner-item {
  position: relative;
}

.atc-list-inner-item {
  position: relative;
}

.remove-product {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.remove-product svg {
  color: #e94e4e;
  opacity: .6;
  transition: all .2s linear;
}

.remove-product:hover svg {
  opacity: 1;
}


.atc-box .atc-items {
  width: 100%;
  justify-content: space-between;
}

@media(max-width:800px){
  .atc-list-inner-item {
    min-width: 250px;
}

.atc-search-box-inner {
    min-width: 250px;
}

.atc-list-inner {
    overflow: visible;
    overflow-x: scroll;
    /* width: 800px; */
}

.atc-list-inner::-webkit-scrollbar-thumb {
  background: linear-gradient(#0a368d1f, #0a368d1f);
}
.atc-search-items::-webkit-scrollbar {
  width: 4px;
}


}