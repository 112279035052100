.user-details{
    .user-dashboard {
        padding: .75rem 1.25rem;
        margin-bottom: 0;
        background-color: #f3f4f6;
        border-bottom: 1px solid #f3f4f6;
        font-weight: 600;
        font-size: 22px;
    }
    .userDetail-row {
        margin: 25px 0;
        .userDetail-4 {
            .userDetail-card {
                .userDetail-body {
                    p {
                        color: #fff;
                    }
                    h6 {
                        color: #fff;
                    }
                }
            }
        }
    }
    .savedProduct {
        margin-top: 70px;
        .my-heading {
          padding: .75rem 1.25rem;
          margin-bottom: 0;
          background-color: #f3f4f6;
          border-bottom: 1px solid #f3f4f6;
          color: #16438d;
          font-weight: 600;
          font-size: 18px;
        }
        table {
            font-size: 14px;
            thead {
                border: 1px solid #ddd;
            }
            tbody {
                tr {
                    border: 1px solid #ddd;
                    td {
                        vertical-align: baseline;
                        img {
                            max-width: 40px;
                            max-height: 40px;
                        }
                    }
                }
            }
        }
       .read-more{
            justify-content: flex-end;
            display: flex;
            padding: 10px;
            .read-btn{
                background: #16438d;
                padding: 6px 10px;
                color: white;
                font-size: 16px;
                border-radius: 20px;
                text-decoration-line: unset;
            }
       }
      }
}
