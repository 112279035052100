$myColor: red;
$logoColor: #16438d;
$whiteColor: #fff;
$navbarColor: #e30613;

$font-size-base: 1rem;
$font-size-sm: ($font-size-base * 0.875);
$border-radius: 0.25rem;
$spacer: 1rem; // 16px

// custom variables
$sidebar-breakpoint: 768px;
$sidebar-base-width: 280px;
$header-md-height: 4.5rem;
$sidebar-spacer-y: $spacer * 0.5;
$font-size-xs: ($font-size-base * 0.75);
$font-weight-semibold: 500;
$default-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
0 1px 2px rgba(0, 0, 0, 0.24);

.category-banner {
  position: relative;

  img {
    width: 100% !important;
    height: 300px;
    object-fit: cover;
  }

  h1 {
    position: absolute;
    top: 36%;
    left: 0;
    right: 0;
    text-align: center;
    align-self: center;
    bottom: 0;
    color: #fff;
  }

  @media (max-width: 575.98px) {
    h1 {
      top: 25%;
      font-size: 28px;
    }
  }
}

.banner {
  padding: 50px 20px;
  background: #fafafa;

  .banner-container {
    .heading {

      // margin-bottom: 35px;
      h2 {
        text-align: center;
        font-weight: 700;
        margin-bottom: 25px;
      }
    }

    .banner-row {
      margin-top: 0px;

      .banner-3 {
        @media (max-width: 767px) {
          order: 1;
        }

        @media (max-width: 575px) {
          order: 0;
        }

        padding: 0 10px 0 0;
        margin: 0;

        .wrap-side-info {
          box-shadow: 0 1px 3px #aeaeaeab;
          background: #fff;

          .heading {
            padding: 25px 25px;
            color: #262626;

            h6 {
              margin-bottom: 0;
              font-size: 20px;
              line-height: 1.2;
            }
          }

          .navItem {
            .accordion {
              ul.category_list {
                padding: 10px 5px;
                margin-top: 0;
                color: #000;

                li {
                  display: flex;
                  // padding: 5px 0;
                  flex-direction: column;
                  position: relative;

                  &:hover {
                    >a {
                      background: #f2f2f2;
                      border-radius: 3px;
                    }
                  }

                  a {
                    color: #aaa;
                    text-transform: uppercase;
                    text-decoration: none;
                    padding: 0 10px;
                    font-size: 12px;
                    padding: 5px 5px;

                    &.best_seller {
                      span {
                        display: block;

                        &.seller_name {
                          text-transform: uppercase;
                        }

                        &.offer_price {
                          padding-top: 10px;

                          b {
                            color: #ef0000;
                          }
                        }
                      }
                    }

                    &.active {
                      color: #0a368d;
                      font-weight: bold;
                      background-color: #aaaaaa78;
                    }
                  }

                  .back_button {
                    position: absolute;
                    top: 0px;
                    right: 0;
                    padding: 5px 20px;
                    color: inherit;
                    cursor: pointer;
                    color: #aaa;
                  }

                  ul {
                    display: none;
                    margin-bottom: 20px;

                    li {
                      a {
                        border-bottom: 1px solid #aaa;
                      }
                    }
                  }

                  &.show {
                    ul {
                      display: block;
                      margin-bottom: 15px;
                    }
                  }
                }
              }

              ul.category_list.best_seller_wrap {
                li {
                  padding-bottom: 20px;
                }
              }

              .card {

                .all-button {
                  width: 100%;

                  button {
                    width: 100%;
                    border-radius: 5px;
                    margin: 10px 0px;
                    background: #16438d;
                    color: white;
                    border: none;
                    padding: 5px 0px;

                    &:hover {
                      background: grey;
                      color: black;
                    }
                  }
                }

                nav {
                  .side-navigation-panel-select-option:hover {
                    border-color: rgba(22, 67, 141, var(--tw-border-opacity)) !important;
                  }

                  .side-navigation-panel-select-option-selected {
                    border-color: rgba(22, 67, 141, var(--tw-border-opacity)) !important;
                  }
                }

                .card-header {
                  margin: 10px 15px;
                  padding: 7px 10px;
                  background-color: #fff;
                  border-bottom: 0;
                  font-size: 16px;
                  font-weight: 700;
                  cursor: pointer;

                  @media (max-width: 575px) {
                    font-size: 14px;
                    margin: 0;
                    padding: 3px 10px;
                  }

                  @media (max-width: 575px) {
                    margin: 1px;
                  }
                }

                .card-header:hover {
                  background: #f2f2f2;
                  border-radius: 3px;
                }

                .collapse {
                  .card-body {
                    padding: 0px 15px 15px 20px;
                    font-size: 14px;

                    nav {
                      ul {
                        padding-left: 5px;
                        list-style-type: none;
                      }
                    }

                    label {
                      font-size: 14px;
                      display: block;
                      margin-bottom: 4px;
                      display: flex;

                      .bfripk {
                        stroke: #0a368db3;
                        stroke-width: 3px;
                        position: relative;
                        top: -7px;
                      }

                      .kwlzbu {
                        align-self: center;
                      }

                      .text {
                        margin-left: 8;
                        position: relative;
                        top: -3px;
                      }

                      .count {
                        position: absolute;
                        right: 18px;
                        color: #92999e;
                        font-size: 14px;
                      }

                      // .sc-hKwCoD {
                      //   position: relative;
                      //   top: -7px;
                      // }
                      .review {
                        display: inline-flex;
                        position: relative;
                        top: -5px;
                        padding-left: 8px;
                        margin-bottom: -5px;
                      }
                    }

                    ul {
                      padding-left: 15px;
                      list-style-type: disc;
                      color: #d6d6d6;
                      line-height: 22px;

                      li {
                        a {
                          color: #262626;
                          text-decoration: none;
                        }
                      }
                    }

                    p {
                      font-size: 14px;
                    }

                    a.vehicle {
                      font-size: 13px;
                      line-height: 15px;
                      padding: 4.5px 9px;
                      background: #f0f0f0;
                      color: #262626;
                      font-weight: 500;
                    }
                  }
                }
              }
            }

            // nav {
            //   padding: 20px;
            //   height: 100%;
            //   ul {
            //     list-style: none;
            //     padding-left: 5px;
            //     margin-bottom: 15px;
            //     position: relative;
            //     li {
            //       font-size: 14px;
            //       svg.side-navigation-panel-select-option-icon-chevron-down {
            //         position: absolute;
            //         right: 0px;
            //         -moz-transition: all 1s linear;
            //         -webkit-transition: all 1s linear;
            //         transition: all 1s linear;
            //         // transform: rotate(-90deg);
            //       }
            //       svg.side-navigation-panel-select-option-icon-chevron-up {
            //         position: absolute;
            //         right: 0px;
            //         -moz-transition: all 1s linear;
            //         -webkit-transition: all 1s linear;
            //         transition: all 1s linear;
            //         // transform: rotate(-180deg);
            //       }
            //     }
            //      ul {
            //        li {
            //         .side-navigation-panel-select-inner-option {
            //           padding: 10px 0 0 0;
            //         }
            //        }
            //      }
            //   }
            //   @media (max-width: 991.98px) {
            //     ul {
            //       margin-bottom: 12px;
            //     }
            //     li {
            //       font-size: 13px !important;
            //     }
            //   }
            // }

            @media (max-width: 1199.98px) {
              nav {
                // height: 29vh;
                overflow-y: scroll;
              }
            }

            @media (max-width: 991.98px) {
              nav {
                // height: 27vh;
                padding: 15px;
                overflow-y: scroll;
              }
            }

            @media (max-width: 575.98px) {}
          }

          .divider {
            padding: 0 0px;

            hr {
              height: 0.1px;
              background: #cdcdcd26;
              margin: 0;
            }
          }
        }

        .latest-product {
          background: #fff;
          margin-top: 22px;
          padding: 20px 20px;
          box-shadow: 0 1px 3px #aeaeaeab;

          @media (max-width: 575px) {
            padding: 10px !important;
            padding-bottom: 45px !important;
            margin-top: 10px;
            display: none;
          }

          &.rang_slider {
            display: block;

            @media (max-width: 575px) {
              font-size: 10px;
              padding: 10px !important;
              margin-bottom: 10px;
            }
          }

          .heading {
            margin: 10px 0px 25px 0px;

            @media (max-width: 575px) {
              margin: 0;
            }

            h6 {
              margin-bottom: 0;
              font-size: 16px;

              @media (max-width: 575px) {
                font-size: 12px;
                margin-bottom: 5px;
              }
            }
          }

          .wrap-product {
            padding: 0 0 15px 0;

            .media {
              img {
                width: 60px;
                height: auto;
                border: 1px solid #ddddddb5;
                border-radius: 3px;
              }

              .media-body {
                a {
                  color: #262626;

                  h6 {
                    margin-bottom: 0;
                    font-size: 14px;
                    line-height: 17px;
                  }

                  @media (min-width: 768px) and (max-width: 991.98px) {
                    h6 {
                      font-size: 12px;
                    }
                  }
                }

                p {
                  margin-bottom: 0;
                  font-size: 13px;
                  font-weight: 600;
                }
              }
            }
          }
        }

        @media (min-width: 768px) and (max-width: 991.98px) {
          .latest-product {
            padding: 20px 5px;
          }
        }

        .rang_slider {
          .heading {
            margin-bottom: 10px;
          }
        }
      }

      @media (max-width: 575.98px) {
        .banner-3 {
          padding: 0 10px 0 10px;
        }
      }

      .banner-9 {
        padding: 0 0 0 10px;

        @media (max-width: 767px) {
          margin-top: 20px;
          padding: 0 10px 0;
        }

        .total-box {
          h6 {
            // font-size: 14px;
            font-weight: 700;
            color: #313131;
            margin-bottom: 0;
          }

          p {
            color: #313131;
            font-size: 14px;
            padding-top: 2px;
          }
        }

        .wrap-tabs {
          background: #e9e9e9;
          padding: 15px;
          border-radius: 10px;
          margin-bottom: 20px;

          .category-sorting {
            display: flex;
            justify-content: space-between;

            .showing-number {
              align-self: flex-end;

              p {
                font-size: 14px;
                margin-bottom: 0;
              }
            }

            .wrap-sorting {
              display: flex;

              div {
                padding: 0 5px;
              }

              .sorting {
                a {
                  color: #030303;
                  font-size: 14px;
                  text-decoration: none;

                  select {
                    padding: 8px 10px;
                    border: 1px solid #b5b5b5;
                  }

                  @media (max-width: 575.98px) {
                    select {
                      width: 78%;
                      margin: 15px 0;
                    }
                  }
                }
              }

              .listing {
                a {
                  border: 1px solid #b5b5b5;
                  padding: 5px 11px;
                  font-size: 20px;
                  position: relative;
                  top: 4px;
                  background: #fff;
                }
              }

              @media (max-width: 575.98px) {
                .listing {
                  float: left;
                }
              }

              .thumbnail {
                a {
                  border: 1px solid #b5b5b5;
                  padding: 5px 11px;
                  font-size: 20px;
                  position: relative;
                  top: 4px;
                  background: #fff;
                }
              }
            }

            @media (max-width: 575.98px) {
              .wrap-sorting {
                display: unset;
              }
            }
          }

          @media (max-width: 575.98px) {
            .category-sorting {
              display: unset;
            }
          }
        }

        @media (max-width: 575.98px) {
          .category-tabs {
            margin-bottom: 15px;
          }
        }

        .categories-row {
          margin-bottom: 25px;
          padding: 0 15px;

          .categories-4 {
            padding: 0;
            border: 0.1px solid #ebebeb;
            margin: 20px 0;

            .card {
              border: 0;
              text-align: center;
              border-radius: 0;

              img {
                max-width: 70%;
                width: 100%;
                margin: auto;
              }

              .card-body {
                padding: 0.25rem;

                h6 {
                  margin-bottom: 2px;
                }

                p {
                  color: #6d767e;
                  font-size: 14px;
                }
              }

              @media (max-width: 991.98px) {
                .card-body {
                  padding: 15px 10px;
                }
              }
            }

            .card:hover {
              background: #f1f1f1;
              transition: 0.3s;
            }
          }
        }

        .result {
          background-color: #fff;
          box-shadow: 0 1px 3px #ddd;
          padding: 15px;

          @media (max-width:575px) {
            display: none;
          }

          @media (max-width: 575px) {
            padding: 1px 15px;
          }

          .result-row {
            .result-5 {
              display: flex;
              align-self: center;

              @media (max-width: 575px) {
                display: none;
              }

              a {
                color: #262626;
                padding-right: 10px;
                text-decoration: none;

                p {
                  font-size: 14px;
                  padding-left: 15px;
                  margin-bottom: 0;
                }

                @media (max-width: 575.98px) {
                  p {
                    font-size: 13px;
                  }
                }

                i {
                  font-size: 20px;
                  padding: 7px 10px;
                }
              }

              a.active {
                i {
                  background: #0a368d;
                  color: #fff;
                  padding: 7px 10px;
                  border-radius: 4px;
                }
              }

              @media (min-width: 768px) and (max-width: 991.98px) {
                .listView-mob {
                  display: none;
                }
              }

              @media (min-width: 576px) and (max-width: 767.98px) {
                .listView-mob {
                  display: none;
                }
              }

              @media (max-width: 575.98px) {
                .listView-mob {
                  display: none;
                }
              }
            }

            @media (min-width: 768px) and (max-width: 991.98px) {
              .result-5 {
                max-width: 100%;
              }
            }

            .result-7 {
              text-align: right;
              font-size: 14px;

              // a {
              //   color: #262626;
              //   padding: 10px;
              //   font-size: 14px;
              //   text-decoration: none;
              @media (max-width: 767px) {
                text-align: left;
                padding-top: 10px;
              }

              .title_wrap {
                display: inline-block;

                .icon {
                  display: none;
                }

                @media (max-width: 575px) {
                  display: none;
                }
              }

              select {
                color: #262626;
                background-color: #ebebeb;
                border: 1px solid #ebebeb;
                font-size: 14px;
                margin-left: 10px;
                padding: 5px 15px;
              }

              @media (max-width: 575.98px) {
                select {
                  padding: 7px 10px;
                  background-color: #fff;
                  font-size: 16px;
                  font-weight: 700;
                  cursor: pointer;
                  width: 100%;
                  margin: 0;
                  line-height: 24px;
                  margin-top: 0;
                  min-height: 38px;
                  margin-top: 10px;

                  &.show_item {
                    display: none;
                  }

                  &:hover {
                    background: #f2f2f2;
                    border-radius: 3px;
                  }
                }
              }

              @media (max-width: 575.98px) {
                span {
                  font-size: 15px;
                  padding-right: 10px;
                  display: block;
                  padding: 0 !important;
                  display: none;

                  &.icon {
                    padding-right: 5px !important;
                    display: inline-block !important;

                    svg {
                      fill: #0a368d;
                      height: 25px;
                      width: 25px;
                    }
                  }
                }
              }

              select:focus {
                outline: 0;
              }

              i {
                color: $logoColor;
              }

              // }
              @media (max-width: 575.98px) {
                a {
                  padding: 10px 0;
                  font-size: 13px;
                }
              }
            }

            @media (min-width: 768px) and (max-width: 991.98px) {
              .result-7 {
                max-width: 100%;
                margin-top: 15px;
              }
            }

            @media (max-width: 575.98px) {
              .result-7 {
                // text-align: left;
                padding: 0;
              }
            }
          }

          @media (min-width: 768px) and (max-width: 991.98px) {
            .result-row {
              display: unset;
            }
          }
        }

        .pagination {
          display: flex;
          justify-content: center;

          ul {
            li {
              .page-link {
                @media (max-width: 767px) {
                  padding: 8px 6px;
                }

                @media (max-width: 575px) {
                  padding: 8px 4px;
                }
              }

              a {
                border: 0;
                background-color: #f0f0f0;
                margin: 0 3px;
                border-radius: 3px;
                color: #262626;
              }
            }
          }
        }

        .listing-row {
          padding: 0 15px;

          .item {
            width: 100%;
            margin-bottom: 15px;

            .item-card {
              background-color: #fff;
              box-shadow: 0 1px 3px #ddd;
              border: 0;

              .item-row {
                .left-3 {
                  img {
                    padding: 25px;
                    height: 210px;
                    object-fit: cover;
                  }

                  span {
                    background: #0a368d;
                    clip-path: polygon(15% 0%, 100% 0%, 85% 100%, 0% 100%);
                    padding: 3px 16px;
                    font-size: 11px;
                    font-weight: 600;
                    border-radius: 4px;
                    position: absolute;
                    top: 15px;
                    left: 15px;
                    color: #fff;
                    text-transform: uppercase;
                  }
                }

                .item-6 {
                  padding: 0 20px 0px 25px;

                  .card-body {
                    a {
                      color: #383838;

                      h6 {
                        margin-bottom: 0;
                      }
                    }

                    .review {
                      display: flex;

                      span.text {
                        font-size: 14px;
                        align-self: center;
                        padding-left: 8px;
                        color: #80878e;
                      }

                      @media (min-width: 992px) and (max-width: 1199.98px) {
                        span.text {
                          font-size: 12px;
                        }
                      }
                    }

                    .des {
                      ul {
                        padding: 5px 0 5px 20px;

                        li {
                          color: #6c757d;
                          font-size: 14px;
                        }
                      }
                    }
                  }
                }

                .right-3 {
                  border-left: 1px solid #ebebeb;
                  text-align: center;
                  padding-top: 20px;

                  h5 {
                    font-family: sans-serif;
                    font-weight: 500;

                    span {
                      font-size: 20px;

                      del {
                        font-size: 16px;
                        color: #999;
                        padding-left: 10px;
                      }
                    }
                  }

                  .add {
                    margin: 25px 0;

                    a {
                      padding: 8.5px 24px;
                      background: #2e57a9;
                      color: #fff;
                      font-size: 14px;
                      border-radius: 4px;
                      text-decoration: none;

                      &:hover {
                        background: #193e88;
                      }
                    }
                  }

                  p {
                    font-size: 14px;
                    color: #262626;
                    margin-bottom: 8px;

                    i {
                      padding-right: 8px;
                      color: #d9d9d9;
                    }

                    a {
                      color: #262626;
                    }
                  }

                  .sku {
                    border-top: 1px solid #ebebeb;
                    margin-top: 10px;

                    p {
                      margin-bottom: 0;
                      color: #999;
                      font-size: 13px;
                      padding: 8px 0;
                    }
                  }
                }
              }
            }
          }
        }

        .featured-row {
          @media (max-width: 575px){
            justify-content: space-around;
          }
          .item {
            padding: 0 8px 16px 8px;

            @media (max-width: 575px) {
              max-width: 45%;
            }

            .card {
              height: 400px;

              @media (max-width: 575px) {
                height: auto;
                font-size: 12px;
              }


              a {
                position: relative;
                transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
                text-decoration: none;

                &:hover {
                  transform: scale(1.05) !important;
                  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
                }

                img {
                  max-width: 90%;
                  height: 162px;
                  margin: auto;
                  padding-top: 25px;
                  object-fit: contain;
                  display: flex;

                  @media (max-width: 575px) {
                    padding-top: 5px;
                    height: 110px;
                  }
                }

                span {
                  background: #f73906;
                  padding: 2px 10px;
                  color: #fff;
                  position: absolute;
                  top: 30px;
                  left: 2px;
                }
              }

              .featured-body {
                overflow: hidden;
                padding: 15px;

                @media (max-width: 575px) {
                  padding: 5px;
                }


                a {
                  display: block;
                  text-decoration: none;

                  h6 {
                    color: #000;
                    font-size: 14px;
                    font-weight: 700;
                    margin-bottom: 0;
                    margin-top: 4px;
                    text-decoration: none;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-transform: capitalize;
                    margin-bottom: 15px;

                    @media (max-width: 575px) {
                      margin-bottom: 5px;
                      font-size: 13px;
                    }
                  }
                }

                p {
                  font-size: 12px;
                  // min-height: 40px;
                  margin-bottom: 12px;

                  @media (max-width: 575px) {
                    margin-bottom: 0;
                  }
                }

                .offer-tag {
                  background: #c8d7e7;
                  border-radius: 3px;
                  padding: 2px 4px;
                  font-size: 14px;
                }

                .review {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-top: 20px;

                  .review_inner {
                    display: flex;
                    align-items: center;
                    background: #cdcdcd;
                    padding: 0px 10px;
                    border-radius: 4px;

                    span.text {
                      align-self: center;
                      padding-left: 5px;
                      font-size: 15px;

                      &.left {
                        padding: 0 5px 0 0;
                      }
                    }
                  }

                  .add-wishlist {
                    margin-right: 15px;
                  }

                  i {
                    font-size: 16px;
                    align-self: center;
                    background: #16438d5c;
                    padding: 12px;
                    color: #16438d;
                    border-radius: 50%;
                    cursor: pointer;


                    &:hover {
                      background: #0a368d;
                      transition: all .5s;
                      align-self: center;
                      padding: 12px;
                      color: #fff;
                      border-radius: 50%;
                    }
                  }


                  @media (min-width: 992px) and (max-width: 1199.98px) {
                    span {
                      font-size: 12px !important;
                    }
                  }
                }

                .cart-detail {
                  display: flex;
                  justify-content: space-between;
                  padding-top: 0px;

                  h5 {
                    align-self: center;
                    margin-bottom: 0;
                    color: #000;
                    font-weight: 700;
                    display: flex;
                    align-items: center;

                    @media (max-width: 575px) {
                      font-size: 12px;
                    }
                  }
                }
              }
            }

            .social {
              opacity: 1;
              display: block;
              padding: 0;
              margin: 0;
              position: absolute;
              z-index: 1;
              right: 0;

              li {
                border-radius: 50%;
                transition: all 0.5s;
                cursor: pointer;
                margin: 10px;

                &:hover {
                  background: #16438d;
                }

                i {
                  color: #16438d;
                  background-color: #16438d5c;
                  font-size: 16px;
                  line-height: 38px;
                  text-align: center;
                  height: 38px;
                  width: 38px;
                  border-radius: 50%;
                  display: block;
                  transition: all 0.3s ease 0s;

                  &:hover {
                    color: white;
                  }
                }
              }
            }

            .card:hover {
              .cart-detail {
                i {
                  font-size: 16px;
                  background: #0a368d;
                  transition: all 0.5s;
                  align-self: center;
                  padding: 12px;
                  color: #fff;
                  border-radius: 50%;
                  cursor: pointer;
                }
              }

              .social {
                li {
                  a {
                    background: #0a368d;
                    color: #fff;

                    &:after {
                      content: "";
                      height: 15px;
                      width: 15px;
                      border-radius: 0;
                      transform: translateX(-50%) rotate(45deg);
                      top: -22px;
                      z-index: -1;
                    }
                  }
                }
              }
            }
          }

          .owl-nav {
            position: absolute;
            top: -65px;
            right: 0;

            button {
              background: #000;
              height: 30px;

              span {
                font-size: 34px;
                padding: 0px 12px;
                position: relative;
                top: -14px;
                color: #fff;
              }
            }
          }

          @media (max-width: 575.98px) {
            .owl-nav {
              top: -71px;
            }
          }
        }
      }

      .pagination {
        ul {
          margin: 15px;
        }
      }

      @media (max-width: 575.98px) {
        .banner-9 {
          margin-top: 0;
        }
      }


      .noUi-target {
        height: 10px;

        .noUi-base {
          .noUi-origin {
            .noUi-handle {
              width: 18px;
              height: 12px;
              right: -15px;
              top: -2px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .banner {
    padding: 10px 5px;
  }
}

.categories {
  padding: 50px 0;

  .categories-container {
    padding: 0;

    .categories-heading {
      background: $navbarColor;
      padding: 15px 10px;
      margin-bottom: 26px;

      h6 {
        margin-bottom: 0;
        color: $whiteColor;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 17px;
        letter-spacing: 0.2px;
      }
    }

    .all-categories {
      text-align: center;
      margin-top: 30px;

      a {
        background: $logoColor;
        color: $whiteColor;
        padding: 15px 40px;
        text-transform: capitalize;
        font-weight: 600;
        font-size: 20px;
        border-radius: 30px;
        text-decoration: none;
      }
    }
  }
}

.banner .banner-container .banner-row .banner-3 .wrap-side-info .navItem .accordion ul.category_list li.section.open>ul {
  display: block;
}

.banner .banner-container .banner-row .banner-9 .featured-row .item .card .featured-body .cart-detail i.fa.fa-shopping-cart.disabled {
  opacity: 0.5;
  color: #ebebeb;
  background: #343a409e;
}

.card-body.featured-body small {
  color: green;
}

@media (max-width: 991px) {
  .all_category_card {
    display: none !important;
  }
}

.not-product h4 {
  color: #ff00009e;
  text-align: center;
}

li.page-item .page-link {
  background-color: #c1c1c1;
}

.page-item.active .page-link {
  color: #fff !important;
  background-color: #007bff !important;
  border-color: #007bff !important;
}

.page-item.disabled .page-link {
  color: #6c757d !important;
  pointer-events: none !important;
  cursor: auto !important;
  background-color: #fff !important;
  border-color: #dee2e6 !important;
}



.review_inner.--m {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.review_inner.--m>div {
  margin-left: 2px;
  margin-right: 8px;
}

@media (max-width: 575px) {
  .section_none {
    display: none !important;
  }
}