* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-size: 14px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
  }
  
  /* /////////// List //////////// */
  ul {
    list-style: none;
  }
  
  a {
    -webkit-transition: 0.3s ease-out;
    -moz-transition: 0.3s ease-out;
    -o-transition: 0.3s ease-out;
    transition: 0.3s ease-out;
  }
  
  img {
    display: inline-block;
    vertical-align: middle;
    margin: auto;
    max-width:100%;
  }
  
  
  /* /////////// Heading /////////// */
  h3 {
    font-size: 22px;
    text-transform: uppercase;
    padding-top: 20px;
  }
  h5 {
    font-size: 15px;
    font-weight: 600;
    padding-top: 10px;
  }
  
  /* //////////// Form ////////// */
  input {
    display: block;
    width: 100%;
    padding: 5px;
    border: none;
    min-height: 40px;
    margin-bottom: 10px;
  }
  
  input[type="checkbox"],
  input[type="radio"] {
    display: inline-block;
    min-height: auto;
    width: auto;
    padding: 0;
    margin: 0;
  }
  
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  
  label {
    display: inline-block;
    margin-bottom: 5px;
  }
  
  .spacing {
    padding: 20px 0;
  }
  
  .button {
    padding: 10px 35px;
    border: 0;
    border-radius: 5px;
    font-size: 16px;
    margin-top: 20px;
    color: #fff;
    transition: background-color 0.3s ease-out;
    background: #00448d;
  }
  
  .cart_button {
    background: #f84819;
    border-color: #f84819;
    border-radius: 0;
    padding: 12px 34px;
    text-transform: uppercase;
    border: 2px solid #f84819;
    transition: background-color 0.3s ease-out;
    color: #fff;
  }
  .cart_button:hover {
    background-color: #f86119;
    border-color: #f86119;
  }
  .button:hover {
    background-color: #0061d5;
  }
  
  @media (max-width: 767px) {
    h3 {
      font-size: 15px;
    }
  }
  
  /* ///////////////////////// Bread Crum ///////////////////////// */
  
  .bread_crum {
    background: #ececec;
    margin-bottom: 15px;
  }
  
  .bread_crum ul {
    display: flex;
    margin-bottom: 0;
  }
  
  .bread_crum ul > li {
    padding: 2px 4px;
  }
  
  .common_pages_inner ol li,
  .common_pages_inner ul li {
    margin: 10px 0;
  }
  
  .common_pages_inner ol > li {
    padding-bottom: 5px;
  }
  
  .bread_crum ul > li:not(:first-child) {
    margin-left: 10px;
  }
  
  .bread_crum ul > li span {
    padding-left: 10px;
  }
  
  .bread_crum ul > li > a {
    color: #000;
  }
  
  .bread_crum ul > li > a:hover {
    color: #0061d5;
  }
  .bread_crum ul > li:last-child span {
    display: none;
  }
  .bread_crum ul > li > a:hover {
    text-decoration: none;
  }
  
  @media (max-width: 575px) {
    .bread_crum {
      display: none;
    }
  }
  
  /* /////////////// Common Pages////////////////////////// */
  
  .common_pages {
    padding: 30px;
    box-shadow: 0 0 10px #ccc;
    margin: 40px 0 20px;
  }
  
  .common_pages .common_pages_heading {
    padding: 5px 0;
    background: #005195;
  }
  
  .common_pages .common_pages_heading h5 {
    padding-left: 10px;
    padding-right: 10px;
    color: #fff;
    font-size: 18px;
  }
  .common_pages_content {
    padding: 20px 10px;
  }
  
  .common_pages_inner p {
    text-align: justify;
  }
  .common_pages_inner ul {
    list-style-type: upper-roman;
    padding-left: 40px;
    line-height: 1.6;
  }
  
  .common_pages_content .date {
    padding-bottom: 15px;
    font-size: 13px;
  }
  
  .common_pages_content h6 {
    padding: 10px 0;
    font-size: 15px;
  }
  
  @media (max-width: 767px) {
    .common_pages_inner ul {
      padding-left: 20px;
    }
    .common_pages {
      padding: 20px;
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }
  
  .disabled {
    cursor: not-allowed !important;
  }
  
  
  /* whatsweb widget */
  
  
  // h1 {
  //   font-size: 20px;
  //   text-align: center;
  //   display: block;
  //   background: linear-gradient(to right top, #6f96f3, #164ed2);
  //   padding: 20px;
  //   color: #fff;
  //   border-radius: 50px;
  // }
  
  /* CSS Multiple Whatsapp Chat */
  .whatsapp-name {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 0;
    margin-bottom: 0;
    line-height: 0.5;
  }
  
  #whatsapp-chat {
    box-sizing: border-box !important;
    outline: none !important;
    position: fixed;
    width: 350px;
    border-radius: 10px;
    box-shadow: 0 1px 15px rgba(32, 33, 36, 0.28);
    bottom: 90px;
    right: 30px;
    overflow: hidden;
    z-index: 99;
    animation-name: showchat;
    animation-duration: 1s;
    transform: scale(1);
  }
  a.blantershow-chat {
    /*   background: #009688; */
    background: #fff;
    color: #404040;
    position: fixed;
    display: flex;
    font-weight: 400;
    justify-content: space-between;
    z-index: 98;
    bottom: 25px;
    right: 30px;
    font-size: 15px;
    padding: 10px 20px;
    border-radius: 30px;
    box-shadow: 0 1px 15px rgba(32, 33, 36, 0.28);
  }
  a.blantershow-chat svg {
    transform: scale(1.2);
    margin: 0 10px 0 0;
  }
  .header-chat {
    /*   background: linear-gradient(to right top, #6f96f3, #164ed2); */
    background: #009688;
    background: #095e54;
    color: #fff;
    padding: 20px;
  }
  .header-chat h3 {
    margin: 0 0 10px;
  }
  .header-chat p {
    font-size: 14px;
    line-height: 1.7;
    margin: 0;
  }
  .info-avatar {
    position: relative;
  }
  .info-avatar img {
    border-radius: 100%;
    width: 35px;
    height: 35px;
    float: left;
    object-fit: contain;
    margin: 0 10px 0 0;
    background-color: #FFF;
  }
  
  a.informasi {
    padding: 20px;
    display: block;
    overflow: hidden;
    animation-name: showhide;
    animation-duration: 0.5s;
  }
  a.informasi:hover {
    background: #f1f1f1;
  }
  .info-chat span {
    display: block;
  }
  #get-label,
  span.chat-label {
    font-size: 12px;
    color: #888;
  }
  #get-nama,
  span.chat-nama {
    margin: 5px 0 0;
    font-size: 15px;
    font-weight: 700;
    color: #222;
  }
  #get-label,
  #get-nama {
    color: #fff;
  }
  span.my-number {
    display: none;
  }
  /* .blanter-msg {
    color: #444;
    padding: 20px;
    font-size: 12.5px;
    text-align: center;
    border-top: 1px solid #ddd;
  } */
  textarea#chat-input {
      border: none;
      font-family: "Arial", sans-serif;
      width: 100%;
      height: 30px;
      outline: none;
      resize: none;
      padding: 10px;
      font-size: 14px;
  }
  
  a#send-it {
    width: 30px;
    font-weight: 700;
    padding: 10px 10px 0;
    background:#eee;
    border-radius: 10px;
    
    svg {
      fill:#a6a6a6;
      height: 24px;
      width: 24px;
    }
  }
  
  .first-msg {
    background: transparent;
    padding: 30px;
    text-align: center;
    & span {
      background: #e2e2e2;
      color: #333;
      font-size: 14.2px;
      line-height: 1.7;
      border-radius: 10px;
      padding: 15px 20px;
      display: inline-block;
    }
  }
  
  .start-chat .blanter-msg {
    display: flex;
  }
  #get-number {
    display: none;
  }
  a.close-chat {
    position: absolute;
    top: 5px;
    right: 15px;
    color: #fff;
    font-size: 30px;
  
  }
  
  @keyframes ZpjSY{
    0% {
      background-color: rgb(182, 181, 186);
    }
    15% {
      background-color: rgb(17, 17, 17);
    }
    25% {
      background-color: rgb(182, 181, 186);
    }
  }
  
  @keyframes hPhMsj {
    15% {
      background-color: rgb(182, 181, 186);
    }
    25% {
      background-color: rgb(17, 17, 17);
    }
    35% {
      background-color: rgb(182, 181, 186);
    }
  }
  
  @keyframes iUMejp {
    25% {
      background-color: rgb(182, 181, 186);
    }
    35% {
      background-color: rgb(17, 17, 17);
    }
    45% {
      background-color: rgb(182, 181, 186);
    }
  }
  
  
  @keyframes showhide {
    from {
      transform: scale(0.5);
      opacity: 0;
    }
  }
  @keyframes showchat {
    from {
      transform: scale(0);
      opacity: 0;
    }
  }
  @media screen and (max-width: 480px) {
    #whatsapp-chat {
      width: auto;
      left: 5%;
      right: 5%;
      font-size: 80%;
    }
  }
  .hide {
    display: none;
    animation-name: showhide;
    animation-duration: 0.5s;
    transform: scale(1);
    opacity: 1;
  }
  .show {
    display: block;
    // animation-name: showhide;
    animation-duration: 0.5s;
    transform: scale(1);
    opacity: 1;
  }
  
  .whatsapp-message-container {
    display: flex;
    z-index: 1;
  }
  
  .whatsapp-message {
    padding: 7px 14px 6px;
    background-color: rgb(255, 255, 255);
    border-radius: 0px 8px 8px;
    position: relative;
    transition: all 0.3s ease 0s;
    opacity: 0;
    transform-origin: center top 0px;
    z-index: 2;
    box-shadow: rgba(0, 0, 0, 0.13) 0px 1px 0.5px;
    margin-top: 4px;
    margin-left: -54px;
    max-width: calc(100% - 66px);
  }
  
  .whatsapp-chat-body {
    padding: 20px 20px 20px 10px;
    background-color: rgb(230, 221, 212);
    position: relative;
    &::before {
      display: block;
      position: absolute;
      content: "";
      left: 0px;
      top: 0px;
      height: 100%;
      width: 100%;
      z-index: 0;
      opacity: 0.08;
      background-image: url("https://elfsight.com/assets/chats/patterns/whatsapp.png");
      // background-image: url(https://res.cloudinary.com/eventbree/image/upload/v1575782560/Widgets/whatsappbg_opt.jpg);
    }
  }
  
  .dAbFpq {
    display: flex;
    z-index: 1;
  }
  
  .eJJEeC {
    background-color: rgb(255, 255, 255);
    width: 52.5px;
    height: 32px;
    border-radius: 16px;
    display: flex;
    -moz-box-pack: center;
    justify-content: center;
    -moz-box-align: center;
    align-items: center;
    margin-left: 10px;
    opacity: 0;
    transition: all 0.1s ease 0s;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.13) 0px 1px 0.5px;
  }
  
  .hFENyl {
      position: relative;
      display: flex;
  }
  
  .ixsrax {
      height: 5px;
      width: 5px;
      margin: 0px 2px;
      border-radius: 50%;
      display: inline-block;
      position: relative;
      animation-duration: 1.2s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      top: 0px;
      background-color: rgb(158, 157, 162);
      animation-name: ZpjSY;
  }
  
  .dRvxoz {
  
      height: 5px;
      width: 5px;
      margin: 0px 2px;
      background-color: rgb(182, 181, 186);
      border-radius: 50%;
      display: inline-block;
      position: relative;
      animation-duration: 1.2s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      top: 0px;
      animation-name: hPhMsj;
  }
  
  .kAZgZq {
      padding: 7px 14px 6px;
      background-color: rgb(255, 255, 255);
      border-radius: 0px 8px 8px;
      position: relative;
      transition: all 0.3s ease 0s;
      opacity: 0;
      transform-origin: center top 0px;
      z-index: 2;
      box-shadow: rgba(0, 0, 0, 0.13) 0px 1px 0.5px;
      margin-top: 4px;
      margin-left: -54px;
      max-width: calc(100% - 66px);
      &::before {
      position: absolute;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAmCAMAAADp2asXAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAACQUExURUxpccPDw9ra2m9vbwAAAAAAADExMf///wAAABoaGk9PT7q6uqurqwsLCycnJz4+PtDQ0JycnIyMjPf3915eXvz8/E9PT/39/RMTE4CAgAAAAJqamv////////r6+u/v7yUlJeXl5f///5ycnOXl5XNzc/Hx8f///xUVFf///+zs7P///+bm5gAAAM7Ozv///2fVensAAAAvdFJOUwCow1cBCCnqAhNAnY0WIDW2f2/hSeo99g1lBYT87vDXG8/6d8oL4sgM5szrkgl660OiZwAAAHRJREFUKM/ty7cSggAABNFVUQFzwizmjPz/39k4YuFWtm55bw7eHR6ny63+alnswT3/rIDzUSC7CrAziPYCJCsB+gbVkgDtVIDh+DsE9OTBpCtAbSBAZSEQNgWIygJ0RgJMDWYNAdYbAeKtAHODlkHIv997AkLqIVOXVU84AAAAAElFTkSuQmCC");
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: contain;
      content: "";
      top: 0px;
      left: -12px;
      width: 12px;
      height: 19px;
  }
  }
  
  .bMIBDo {
      font-size: 13px;
      font-weight: 700;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.4);
  }
  
  .iSpIQi {
      font-size: 14px;
      line-height: 19px;
      margin-top: 4px;
      color: rgb(17, 17, 17);
  }
  
  .iSpIQi {
      font-size: 14px;
      line-height: 19px;
      margin-top: 4px;
      color: rgb(17, 17, 17);
  }
  
  .cqCDVm {
      text-align: right;
      margin-top: 4px;
      font-size: 12px;
      line-height: 16px;
      color: 
      rgba(17, 17, 17, 0.5);
      margin-right: -8px;
      margin-bottom: -4px;
  }
  
  @media(max-width:991px){
    a.blantershow-chat {
      bottom: 85px;
  }
  }