.animation-main{
    justify-self: center;
    justify-items: center;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    z-index: 999;
}