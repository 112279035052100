.cart-page-wrap {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #f3f5f9;
}

.top-section h1 {
  font-size: 25px;
  line-height: 1.2;
  color: #343a40;
}

.cart-page-wrap .sc-top-section {
  border: 1px solid #ebebeb;
  background-color: #fff;
  padding: 10px;
  margin-top: 15px;
  margin-bottom: 25px;
  background-color: #fff;
  border-left: 3px solid #005195;
}

.cart-page-wrap .sc-top-section span {
  font-weight: 700;
  font-size: 17px;
  line-height: normal;
  margin-bottom: 0;
  margin-bottom: 8px;
  display: inline-block;
}

.cart-page-wrap .sc-top-section p {
  font-size: 13px;
  line-height: 1.2;
  margin-bottom: 0;
  color: #000;
  letter-spacing: 0.2px;
}

.cart-page-wrap .sc-item-wrap {
  border: 1px solid #ebebeb;
  background-color: #fff;
  padding: 12px;
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: #fff;
  overflow: hidden;
}

.cart-page-wrap .sc-item-row {
  display: flex;
  flex-wrap: wrap;
}

.cart-page-wrap .sc-item-col.cm-col-2 {
  width: 50%;
}

.cart-page-wrap .sc-item-col.cm-col-1 {
  width: 50%;
}

.cart-page-wrap .sc-item-col.cm-col-1 {
  display: flex;
  flex-wrap: nowrap;
}

.cart-page-wrap .sc-item-inner-col {
  width: 33.33%;
  text-align: center;
}

.cart-page-wrap .col-name h6 {
  font-size: 14px;
  text-transform: uppercase;
}
.cart-page-wrap .empty-cart {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 57px;
  padding: 6px;
  flex-wrap: wrap;
  border: 1px solid #ebebeb;
  background-color: #fff;
  padding: 10px;
  margin-top: 15px;
  margin-bottom: 25px;
  background-color: #fff;
}
.cart-page-wrap .empty-cart span {
  display: block;
  width: 100%;
  text-align: center;
  color: #4897eb38;
}
.cart-page-wrap .empty-cart p {
  width: 100%;
  font-size: 25px;
  letter-spacing: 2px;
  text-align: center;
  color: #4897eb4a;
  font-weight: 600;
}
.sc-item-info img {
  max-width: 152px;
  max-height: 100px;
  width: 100%;
  border: 1px solid #ebebeb;
  padding: 5px;
  object-fit: cover;
}
.sc-item-img {
  width: 30%;
}

.sc-item-info {
  flex-wrap: nowrap;
  align-items: flex-start;
  display: flex;
}

.info {
  padding-left: 10px;
  width: 70%;
  padding-right: 10px;
}

.info p {
  margin-bottom: 0;
  font-size: 12px;
  line-height: 1.5;
}

.info h6 {
  font-size: 15px;
  color: #005195;
  text-transform: capitalize;
}

.info p span {
  font-weight: 700;
  color: #005195;
}
.eelete-icon {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

span.itemDelete {
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  opacity: 0.5;
  color: red;
  transition: all 0.2s linear;
}

span.itemDelete:hover {
  color: red;
  transform: scale(1.2);
}

.si-qty-count span {
  display: inline-block;
  padding: 2px 6px;
  border: 1px solid #e4e5e5;
  cursor: pointer;
  font-weight: 700;
  color: #005195;
  font-size: 13px;
  background-color: #f3f4f6;
  transition: background-color 0.15s ease-in-out;
}

.si-qty-count span {
  display: inline-block;
  padding: 2px 6px;
  border: 1px solid #e4e5e5;
  cursor: pointer;
  font-weight: 700;
  color: #005195;
  font-size: 13px;
  background-color: #f3f4f6;
  transition: background-color 0.15s ease-in-out;
}

.si-qty-count span:hover {
  background-color: #aaaaaaa6;
}

.si-qty-count span.count:hover {
  background-color: #f3f4f6;
}

.shopingCart .si-qty-count span.count {
  cursor: text;
}

.cart-page-wrap .si-qty-count {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
}

.order-summary-wrap {
  margin-top: 48px;
  border: 1px solid #ebebeb;
  background-color: #fff;
  padding: 18px;
  margin-bottom: 15px;
  background-color: #fff;
  position: sticky;
  top: 27px;
}

.order-summary-wrap h5 {
  font-size: 18px;
  line-height: 1.2;
  color: #343a40;
  padding-top: 0;
}

.order-summary-wrap .subtotal {
  display: flex;
  justify-content: space-between;
}

.order-summary-wrap .item {
  margin-bottom: 20px;
}

.order-summary-wrap .subtotal p {
  margin-bottom: 5px;
}

.order-summary-wrap .subtotal p span {
  font-weight: 700;
  color: #005195;
}

.order-summary-wrap .total {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
}

.order-summary-wrap .checkout button {
  display: inline-block;
  width: auto;
  text-align: center;
  padding: 10px 20px;
  color: #fff !important;
  border-radius: 3px;
  font-size: 15px;
  margin-top: 0;
  background: #00448d;
  transition: 0.3s ease-out;
  border: navajowhite;
  border-radius: 40px;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.order-summary-wrap .checkout {
  margin-top: 40px;
  margin-bottom: 10px;
}

.order-summary-wrap .checkout button:hover {
  opacity: 0.9;
}
.cart-page-wrap .sc-item-inner-col p {
  font-weight: 700;
  color: #005195;
}

@media (max-width: 575px) {
  .cart-page-wrap .sc-item-col.cm-col-2 {
    width: 100%;
    margin-bottom: 20px;
  }

  .cart-page-wrap .sc-item-col.cm-col-1 {
    width: 100%;
  }
  .order-summary-wrap .total h4 {
    font-size: 15px;
    font-weight: bold;
  }
  .cart-page-wrap .col-name h6 {
    font-size: 12px;
  }
  .cart-page-wrap .sc-item-inner-col p {
    margin: 0;
  }
  .order-summary-wrap h5 {
    font-size: 14px !important;
  }
}


.info p span.gst-span-product-page {
  font-size: 10px;
  margin-left: 5px;
  font-style: italic;
}

.order-summary-wrap .checkout button.add-product-to-checkout {
  background: #f73400;
  opacity: .5;
  font-size: 12px;
  cursor: not-allowed;
}
