
.choose-user-type-wrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 100%;
}

.choose-user-type-wrap .user-type {
    width: 50%;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    height: 100%;
    padding-bottom: 30px;
    padding-top: 30px;
}

.choose-user-type-wrap .user-type:not(:first-child) {
    border-left: 1px solid #00448d6b;
}

.choose-user-type-wrap .content h6 {
    font-size: 18px;
    margin-top: 15px;
}

.choose-user-type-wrap .content h6>a {
    text-transform: none;
}

.choose-user-type-wrap .content h6>a:hover {
    text-decoration: none;
}

.choose-user-type-wrap .user-type .content button {
    display: inline-block;
    width: auto;
    text-align: center;
    padding: 8px 30px;
    color: #fff !important;
    border-radius: 3px;
    font-size: 15px;
    margin-top: 0;
    border: 0;
    background: #00448d;
    transition: all.2s ease;
}

.choose-user-type-wrap .user-type .content button:hover {
    opacity: .9;
}
