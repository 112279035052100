.success_message {
  padding: 50px 0;
  .message {
    margin: auto;
    border-radius: 20px;
    border: 1px solid #dee2e6;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 50px 30px;
    text-align: center;

    .icon {
      font-size: 70px;
      margin-bottom: 20px;

      svg {
        fill: #009a75;
      }
    }

    .button_wrap {
      margin-top: 10px;
      a {
        margin: 5px;
        background: #00448d;
        border-color: #00448d;
        padding-top: 5px;
        padding-bottom: 5px;

        &:hover {
          background: #0061d5;
          border-color: #0061d5;
        }
      }
    }
  }
}
